import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';

const estateImage = `${process.env.PUBLIC_URL}/images/estate.png`
const currencyImage = `${process.env.PUBLIC_URL}/images/currency.png`
const stockImage = `${process.env.PUBLIC_URL}/images/stock.png`
const propertyImage = `${process.env.PUBLIC_URL}/images/property.png`
const artsImage = `${process.env.PUBLIC_URL}/images/arts.png`


const items = [
    {
        title: 'Real Estate and Land',
        img: estateImage,
        desc: 'Explore the potential of tokenized real estate and land with BitViraj. Transform physical properties into digital assets, enabling fractional ownership and increased liquidity. Invest in residential, commercial, and industrial properties worldwide. Benefit from secure and transparent blockchain transactions. Experience a new era of real estate investment today!',
    },
    {
        title: 'Currencies',
        img: currencyImage,
        desc: 'Tokenize traditional currencies with Originals to unlock new investment opportunities. Convert fiat currencies into digital tokens, enabling secure and transparent transactions. Enjoy enhanced liquidity, fractional ownership, and global accessibility. Revolutionize your approach to currency investment with blockchain technology.',
    },
    {
        title: 'Stocks and Securities',
        img: stockImage,
        desc: 'Tokenize stocks and securities with Originals for a modern investment approach. Convert traditional shares into digital tokens, allowing for fractional ownership and increased liquidity. Enjoy secure, transparent transactions and global accessibility. Enhance your portfolio with the power of blockchain technology.',
    },
    {
        title: 'Intellectual Property',
        img: propertyImage,
        desc: 'Unlock the value of intellectual property with Originals tokenization. Convert patents, trademarks, and copyrights into digital tokens, allowing for fractional ownership and easier monetization. Enjoy secure, transparent transactions and global reach. Revolutionize your IP investments with blockchain technology.',
    },
    {
        title: 'Arts and Antiques',
        img: artsImage,
        desc: 'Tokenize valuable art and antiques with Originals to access new investment possibilities. Convert masterpieces and collectibles into digital tokens, enabling fractional ownership and enhanced liquidity. Benefit from secure, transparent transactions and global market reach. Preserve and invest in cultural treasures with blockchain technology.',
    },

]

function Tokenisation() {
  return (
    <section className=' w-full p-5 bg-gradient-to-br from-[#2691D7] via-[#04A287] to-[#04A287]  flex flex-col'>
        <div className=' flex mt-5 w-full justify-center items-center gap-5 z-10'>
            <div className=' bg-white w-52 h-1'></div>
            <h1 className=' text-center lg:text-5xl text-2xl font-bold text-white'>Tokenization</h1>
            <div className=' bg-white w-52 h-1'></div>
        </div>
        <div className=' my-10 md:px-20 px-2'>
            <p className=' text-white text-xl font-semibold'>Unlock new investment opportunities with BitViraj's tokenization of real-world assets. Own fractions of high-value properties with ease, increasing liquidity and accessibility. Benefit from secure, transparent transactions powered by blockchain technology. Diversify your portfolio with global real estate investments. Experience the future of asset management today! </p>
        </div>

        <div className=' bg-white rounded-lg p-10 flex flex-col w-full'>

        <h1 className=' text-4xl w-full text-center font-bold mb-10 '>What Assets Can Be Tokenized?</h1>

        <div className='  px-2 md:px-10 relative mt-10'>
        <Swiper
        spaceBetween={30}
        centeredSlides={true}
        effect={'fade'}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        {items.map((d,i) => (
            <SwiperSlide>
            <div className=' flex xl:flex-row flex-col w-full justify-between bg-white'>
            <div className=' xl:w-1/2 w-full flex justify-center'>
                <img src={d.img} alt='real-world-assets' />
            </div>

            <div className='xl:w-1/2 w-full flex flex-col p-2 md:p-10'>
                <h1 className=' text-neutral-600 font-semibold text-3xl'>{d.title}</h1>

                <p className=' mt-5 md:text-xl text-base font-semibold text-neutral-500'>{d.desc}</p>
            </div>

        </div>
        </SwiperSlide>
        ))}

        
      </Swiper>
      
    
    </div>

            
        
        </div>
    </section>
  )
}

export default Tokenisation