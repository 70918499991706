
import React from 'react'
import { FaTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaArrowCircleDown } from "react-icons/fa";
import logo from "../../assets/images/logo.png"
import { Link } from 'react-router-dom';

const footItems = [
    {
        label: "Marketplace",
        link: "#",
        children: [
            {
                label: "Create A Store",
                link: "#",
            },
            {
                label: "Start Selling",
                link: "#",
            },
            {
                label: "My Account",
                link: "#",
            },
            {
                label: "Job",
                link: "#",
            },
            {
                label: "List a Item",
                link: "#",
            },
        ]
    },
    {
        label: "Community",
        link: "#",
        children: [
            {
                label: "Art",
                link: "#",
            },
            {
                label: "Digital Art",
                link: "#",
            },
            {
                label: "Photography",
                link: "#",
            },
            {
                label: "Games",
                link: "#",
            },
            {
                label: "Music",
                link: "#",
            },
            
        ]
    },
    {
        label: "Resources",
        link: "#",
        children: [
            {
                label: "Explore NFT's",
                link: "#",
            },
            {
                label: "Platform Status",
                link: "#",
            },
            {
                label: "Help Center",
                link: "#",
            },
            {
                label: "Partners",
                link: "#",
            },
            {
                label: "Marketplace",
                link: "#",
            },
        ]
    },

]



function Footer() {
  return (
    <div className="w-full flex flex-col lg:px-20 px-10 pt-14 pb-5 bg-[#f7fcf7] ">
      <div className="flex lg:flex-row flex-col justify-between pb-10">

        {/* About section */}
        <section className="flex flex-col mb-5 items-center lg:items-start w-full lg:w-1/4">
          <img src="/images/bmLogo.svg" alt="logo" className="w-50" />
          <p className="text-neutral-500 ">
            Join our global community to connect with like-minded individuals,
            stay up-to-date with the latest news and developments, and access
            exclusive content and events.
          </p>
          <div className="flex pt-2 gap-3">
            <Link to={""}>
              <FaFacebook className="text-4xl text-blue-900 rounded-full" />
            </Link>
            <Link to={""}>
              <FaLinkedinIn className="bg-sky-800 py-2 text-4xl text-white rounded-full" />
            </Link>
            <Link to={""}>
              <FaTwitter className="bg-sky-400 py-2 text-4xl text-white rounded-full" />
            </Link>
          </div>
        </section>

        {/* Service Section */}
        <section className="flex justify-center w-full">
          <div className="flex lg:gap-10 md:gap-5 w-full lg:w-auto justify-around gap-2 flex-wrap">
            {footItems.map((d, i) => (
              <button
                key={i}
                href={d.link ?? "#"}
                className=" group px-2 transition-all"
              >
                <p className="flex cursor-pointer font-semibold gap-2 text-black-400">
                  <span className="text-lg">{d.label}</span>
                </p>

                {d.children && (
                  <div className=" w-auto flex-col gap-1 py-3 transition-all ">
                    {d.children.map((ch, ind) => (
                      <Link
                        key={ind}
                        href={ch.link ?? "#"}
                        className="flex cursor-pointer items-center py-1 text-neutral-400 hover:text-black"
                      >
                        <span className="whitespace-nowrap pl-3">
                          {ch.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                )}
              </button>
            ))}
          </div>
        </section>

            {/* Language Section  */}
        <section className='flex flex-col items-center'>
          <p className="flex cursor-pointer font-semibold gap-2 text-black-400">
            <span className="text-lg">Language</span>
          </p>

          <div class="relative inline-flex px-8 pt-2 ">
            <FaArrowCircleDown className='rounded-full absolute top-1 right-8 m-4 pointer-events-none' />
            <select class="border-black border rounded-full text-gray-600 h-10 pl-10 pr-14  bg-white hover:border-gray-400 focus:outline-none appearance-none">
              <option>English</option>
              <option>Hindi</option>
              <option>Spanish</option>
              <option>German</option>
              <option>Russian</option>
            </select>
          </div>
        </section>
      </div>


      <div className="flex justify-between py-5 border-t-2 border-black">
        <span>&#169; Copyright 2024-BlockMosaic All rights Reserved</span>
        <div>
          <Link href={"#"} className="hover:text-neutral-500">
            Privacy Policy{" "}
          </Link>
          |
          <Link href={"#"} className="hover:text-neutral-500">
            {" "}
            Terms & Conditions{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer