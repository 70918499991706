import React from 'react'
import Skeleton from '@mui/material/Skeleton'

function NftLoader({cards}) {
  return (
    Array(cards).fill(0).map(item => <div className='w-[377px] h-fit rounded-xl pb-5 flex flex-col xl:scale-100 scale-90'>
        <Skeleton  variant="rounded" width={377} height={290} />
        <div className='flex flex-col w-full p-5 gap-5'>
          <div className='flex gap-3'>
          <Skeleton variant="rounded" width={50} height={20} /> 
          <Skeleton variant="rounded" width={50} height={20} /> 
          <Skeleton variant="rounded" width={50} height={20} /> 
          </div>
          <div className='flex flex-col gap-5 w-full'>
          <Skeleton variant="rounded" width={150} height={30} /> 
          <Skeleton variant="rounded" width={80} height={30} />
          </div>
          
        </div>
      </div> )
    
  )
}

export default NftLoader