import React from 'react'
import { AiOutlineRise } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'
import { HiHome } from 'react-icons/hi2'
import { IoMdCheckmark } from 'react-icons/io'
import { PiWineFill } from 'react-icons/pi'
import { SiLegacygames } from 'react-icons/si'

function Storyline() {
  return (
    <div className=' w-full flex flex-col py-10 bg-white gap-8'>
        <h1 className=' text-3xl md:text-5xl text-neutral-700 font-semibold text-center'>The Legacy Tower</h1>

        {/* Timeline */}

        <div class=" mt-5 flex items-center pl-12 pr-6 md:px-32 bg-white">
            <div>

                <div class="relative ">                  
                <div class="mt-6 bg-gradient-to-b from-emerald-500 via-emerald-500 to-blue-500 pl-2">
                    <div className=' bg-white w-full pl-20 pb-28'>
                   <h1 className=' text-3xl md:text-4xl drop-shadow-[3px_4px_var(--tw-shadow-color)] shadow-neutral-200 font-bold text-primary-1 '>Foundation of Ambition</h1>
                   <p className=' mt-6 text-neutral-600 text-lg md:text-2xl font-semibold'> In the heart of a bustling metropolis, a visionary architect, Emily Hayes, sketches her dream of The Legacy Tower—a structure that symbolizes unity, innovation, and human achievement. Determined to defy architectural norms, she envisions a building that blends sustainability with grandeur. </p>
                    </div>
                </div>
                <div class="absolute flex items-center justify-center -top-6 -left-10 h-20 w-20 rounded-full shadow-xl shadow-[#B0DDD5] bg-gradient-radial from-[#B0DDD5] to-white"> <HiHome className=' rounded-full backdrop-blur-sm text-4xl text-primary-1' /></div>
                </div>


                <div class="relative ">                   
                <div class="mt-6 bg-gradient-to-b from-blue-500 via-blue-500 to-[#bf3636] pl-2">
                    <div className=' bg-white w-full pl-20 pb-28'>
                   <h1 className=' text-3xl md:text-4xl font-bold text-blue-500 drop-shadow-[3px_4px_var(--tw-shadow-color)] shadow-neutral-200 '>The Rise</h1>
                   <p className=' mt-6 text-neutral-600 text-lg md:text-2xl font-semibold'> Construction begins amidst skepticism and challenges. As steel rises and glass panels gleam under the sun, the city watches in awe and anticipation. Emily battles technical setbacks, financial constraints, and personal doubts, but her team's resilience and passion keep them moving forward. </p>
                    </div>
                </div>
                <div class="absolute flex items-center justify-center -top-6 -left-10 h-20 w-20 rounded-full shadow-xl shadow-[#c1dbec] bg-gradient-radial from-[#c1dbec] to-white"> <AiOutlineRise className=' rounded-full backdrop-blur-sm text-4xl text-blue-500' /></div>
                </div>


                <div class="relative ">                   
                <div class="mt-6 bg-gradient-to-b from-[#bf3636] via-[#bf3636] to-[#259A31] pl-2">
                    <div className=' bg-white w-full pl-20 pb-28'>
                   <h1 className=' text-3xl md:text-4xl font-bold text-[#bf3636] drop-shadow-[3px_4px_var(--tw-shadow-color)] shadow-neutral-200 '>Struggles and Triumphs</h1>
                   <p className=' mt-6 text-neutral-600 text-lg md:text-2xl font-semibold'> Midway through, unforeseen issues threaten the project. Environmental protests, political opposition, and economic downturns cast shadows over the tower's future. Yet, Emily's unwavering belief in her vision inspires a city-wide movement of support. The tower becomes a symbol of hope and resilience. </p>
                    </div>
                </div>
                <div class="absolute flex items-center justify-center -top-6 -left-10 h-20 w-20 rounded-full shadow-xl shadow-[#e8c7c7] bg-gradient-radial from-[#e8c7c7] to-white"> <PiWineFill className=' rounded-full backdrop-blur-sm text-4xl text-[#bf3636]' /></div>
                </div>


                <div class="relative ">                   
                <div class="mt-6 bg-gradient-to-b from-[#259A31] via-[#259A31] to-[#391DE2] pl-2">
                    <div className=' bg-white w-full pl-20 pb-28'>
                   <h1 className=' text-3xl md:text-4xl font-bold text-[#259A31] drop-shadow-[3px_4px_var(--tw-shadow-color)] shadow-neutral-200 '>Completion and Revelation</h1>
                   <p className=' mt-6 text-neutral-600 text-lg md:text-2xl font-semibold'> Finally, as the last piece is fitted into place, The Legacy Tower stands tall—a marvel of architecture, engineering, and human spirit. Its grand opening is a celebration of unity, featuring cultural performances, technological innovations, and speeches that echo Emily's vision of a better future. </p>
                    </div>
                </div>
                <div class="absolute flex items-center justify-center -top-6 -left-10 h-20 w-20 rounded-full shadow-xl shadow-[#C8E1CA] bg-gradient-radial from-[#C8E1CA] to-white"> <IoMdCheckmark className=' rounded-full backdrop-blur-sm text-4xl text-[#259A31]' /></div>
                </div>


                <div class="relative ">                   
                <div class="mt-6  pl-2">
                    <div className=' bg-white w-full pl-20 pb-28'>
                   <h1 className=' text-3xl md:text-4xl font-bold text-[#391DE2] drop-shadow-[3px_4px_var(--tw-shadow-color)] shadow-neutral-200 '>Legacy</h1>
                   <p className=' mt-6 text-neutral-600 text-lg md:text-2xl font-semibold'> Years pass, and The Legacy Tower becomes an integral part of the city skyline. It houses businesses, cultural institutions, and a research center dedicated to sustainable technologies. Emily's legacy lives on, inspiring future generations to dream big and build even higher. </p>
                    </div>
                </div>
                <div class="absolute flex items-center justify-center -top-6 -left-10 h-20 w-20 rounded-full shadow-xl shadow-[#CAC4EF] bg-gradient-radial from-[#CAC4EF] to-white"> <SiLegacygames className=' rounded-full backdrop-blur-sm text-4xl text-[#391DE2]' /></div>
                </div>
            </div>
        </div>

        
    </div>
  )
}

export default Storyline