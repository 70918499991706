import React, { useState } from "react";
import { Modal, Typography } from "@mui/material";
import axios from "axios";
import { useWalletInfo } from "../../../hooks/useWalletInfo";
import toast from "react-hot-toast";
import SpinLoader from "../../../componets/loader/SpinLoader";

const ConfirmInvestmentModal = ({
  open,
  handleClose,
  nftId,
  price,
  priceType,
}) => {
  const { fetchWalletInfo } = useWalletInfo();

  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleConfirm = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/v0/user/investNow-staking`,
        new URLSearchParams({
          nftId,
          price,
          priceType,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: token,
          },
        }
      );
      console.log("Investment successful:", response.data);
      setIsLoading(false);
      toast.success("Successfully Invested");
      handleClose();
      await fetchWalletInfo();
    } catch (error) {
      console.error("Error making investment:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=" w-[400px] h-[250px] flex flex-col  items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-lg bg-white">
        {isLoading && <SpinLoader />}

        <h1 className=" text-2xl font-semibold">Confirm Investment</h1>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to invest?
        </Typography>
        <div className=" mt-6 flex gap-8">
          <button
            className=" px-3 py-1 rounded-md text-lg hover:scale-95 text-white bg-primary-1"
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button
            className=" px-3 py-1 rounded-md text-lg hover:bg-red-500 hover:text-white text-red-500 outline outline-red-500 bg-white"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmInvestmentModal;
