import React, { useState } from "react";

const TransactionTable = ({ data }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div>
      <div className="overflow-x-auto rounded">
        <table className="min-w-full bg-[#04A287] border-gray-200 rounded-md">
          <thead>
            <tr className="text-white">
              <th className="py-4 sm:py-6 px-4 sm:px-6 text-left rounded-tl-md">
                Username
              </th>
              <th className="py-4 sm:py-6 px-4 sm:px-6 text-left">Amount</th>
              <th className="py-4 sm:py-6 px-4 sm:px-6 text-left">Status</th>
              <th className="py-4 sm:py-6 px-4 sm:px-6 text-left rounded-tr-md">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((transaction, index) => (
              <tr
                key={index}
                className={`border-t border-gray-200 ${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                }`}
              >
                <td className="py-4 px-4 sm:px-6 text-sm font-semibold text-gray-700">
                  {transaction.userId}
                </td>
                <td className="py-4 px-4 sm:px-6 text-sm font-semibold text-gray-700">
                  {transaction.price}
                </td>
                <td
                  className={`py-4 px-4 sm:px-6 text-sm font-bold ${
                    transaction.transactionStatus === "SUCCESS"
                      ? "text-green-500"
                      : transaction.transactionStatus === "FAILED"
                      ? "text-red-500"
                      : "text-yellow-500"
                  }`}
                >
                  {transaction.transactionStatus}
                </td>
                <td className="py-4 px-4 sm:px-6 text-sm font-semibold text-gray-700">
                  {transaction.createdAt}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 px-2 py-1 rounded ${
              currentPage === index + 1
                ? "bg-[#04A287] text-white"
                : "bg-gray-300 text-gray-700"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TransactionTable;
