import React, { useEffect, useState } from "react";
import TransactionTable from "./TransactionTable";
import axios from "axios";

const TransactionDetails = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${baseUrl}/v0/transactions`, {
        headers: {
          token: token,
        },
      });
      setData(response.data.responseData);
    };
    fetchData();
  }, []);
  return (
    <div className="p-4 sm:p-6">
      <header className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-700 mb-4 lg:mb-0">
          Transactions
        </h1>
        <div className="relative w-full lg:w-auto">
          <input
            type="text"
            placeholder="Search"
            className="w-full pl-10 pr-4 py-2 border border-gray-400 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-gray-500 text-sm font-bold"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </div>
      </header>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6 bg-[#04A287] rounded-md text-white p-4 sm:p-6">
        <div className="text-center p-4">
          <h2 className="text-xl sm:text-2xl font-bold">15800</h2>
          <p className="font-bold">Total Transactions</p>
        </div>
        <div className="text-center p-4">
          <h2 className="text-xl sm:text-2xl font-bold">11200</h2>
          <p className="font-bold">Completed Transactions</p>
        </div>
        <div className="text-center p-4">
          <h2 className="text-xl sm:text-2xl font-bold">1800</h2>
          <p className="font-bold">Pending Transactions</p>
        </div>
        <div className="text-center p-4">
          <h2 className="text-xl sm:text-2xl font-bold">2800</h2>
          <p className="font-bold">Failed Transactions</p>
        </div>
        <div className="text-center lg:p-5 lg:relative md:col-span-2 lg:col-span-1 flex justify-center">
          <button className="flex items-center space-x-2 bg-gray-100 px-4 py-2 rounded-full text-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-filter-left"
              viewBox="0 0 16 16"
            >
              <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
            </svg>
            <span className="font-semibold text-sm">Sort By</span>
          </button>
        </div>
      </div>
      <TransactionTable data={data} />
    </div>
  );
};

export default TransactionDetails;
