import React, { useContext, useEffect, useState } from "react";
import bannerPlaceholder from "../../../assets/images/profile/profileBanner.jpeg";
import profilePlaceholder from "../../../assets/images/profile/pic2.jpg";
import axios from "axios";
import { ProfileContext } from "../../../hooks/useProfileData";

function TopBanner() {
  const { profileData, setProfileData } = useContext(ProfileContext);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      axios
        .get(`${baseUrl}/v0/user/profile?email=${email}`)
        .then((response) => {
          const data = response.data.responseData;
          if (data) {
            setProfileData({
              profileImage: data.profilePic || profilePlaceholder,
              bannerImage: data.bannerImage || bannerPlaceholder,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    }
  }, []);

  return (
    <div className="relative w-full flex mb-14 flex-col">
      <div className=" w-full h-min-[158px]">
        <img
          src={profileData.bannerImage}
          alt="banner-img"
          className=" w-full h-[130px]"
        />
      </div>
      <img
        src={profileData.profileImage}
        alt="user"
        className=" w-24 rounded-full border-8 left-1/2 -translate-x-1/2 border-white absolute -bottom-10 "
      />
    </div>
  );
}

export default TopBanner;
