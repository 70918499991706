import React, { useEffect, useState } from 'react';
import CurvedCarousel from './curvedCarousel';
import { get } from "../../Service/Apiservice"

const dummyImages = [

  { id: 2, src: '/images/c2.png', alt: 'Image 2' },
  { id: 3, src: '/images/c3.png', alt: 'Image 3' },
  { id: 4, src: '/images/c4.png', alt: 'Image 4' },
  { id: 5, src: '/images/c5.png', alt: 'Image 5' },
  { id: 1, src: '/images/c1.png', alt: 'Image 1' },
];

const Carousel = () => {
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSelect = (originalIndex) => {
    console.log(`Selected image index: ${originalIndex}`);
  };
  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      try {
        const response = await get(
          `v0/collection/collection-list`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(response.responseData[0]?.data, "response");
        setCollection(response.responseData[0]?.data);
        // setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProject();
  }, []);
  return (
    <div>

      {collection.length > 0 &&
        <CurvedCarousel
          childWidth={200}
          curve={50}
          spacing={39}
          rotation={true}
          friction={0.9}
          dragging={true}
          onSelect={handleSelect}
        >
          {collection.map((image, index) => (
            <div>
              <br />
              <div className='rounded-lg border border-solid border-stone-200 relative'>

                {image.collectionImage && <img
                  key={index}
                  src={image.collectionImage}
                  alt={index}
                  originalIndex={index}
                  className='rounded-tl-lg rounded-tr-lg'
                  style={{ cursor: 'pointer', width: 250, height: '200px' }}
                />}
                <div className='absolute top-184px bg-primary-purple-1 flex items-center gap-x-0.5 p-1 justify-center' style={{clipPath: "polygon(100% 0%, 91% 50%, 100% 100%, 0 100%, 0% 50%, 0 0)"}}>
                  <div className='flex items-center gap-x-0.5'>
                    <img className='w-3.5 h-3.5' src="/images/plus.png" alt='icon' />
                    <p className='text-sm font-bold text-white'>&nbsp;Earn Upto 25270 ST &nbsp;&nbsp;&nbsp;</p>
                  </div>
                </div>
                <div className='pt-5 pb-2 px-2 flex flex-col gap-2.5'>
                  <div className='flex items-center gap-x-0.5'>
                    <img className='w-2.5 h-2.5' src="/images/yellow fire.png" alt='icon' />
                    <p className='text-sm font-normal'>187 <span className='text-zinc-400'>raised till now</span></p>
                  </div>
                  <div className='flex items-center gap-x-0.5'>
                    <img className='w-3.5 h-3.5' src="/images/Contributors.png" alt='icon' />
                    <p className='text-sm font-normal'>3 Contributors</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CurvedCarousel>
      }
    </div>
  );
};

export default Carousel;
