// LocationDataContext.js
import React, { createContext, useContext, useState } from "react";

const LocationDataContext = createContext(null);

export const useLocationData = () => useContext(LocationDataContext);

export const LocationDataProvider = ({ children }) => {
  const [locationData, setLocationData] = useState(null);

  return (
    <LocationDataContext.Provider value={{ locationData, setLocationData }}>
      {children}
    </LocationDataContext.Provider>
  );
};
