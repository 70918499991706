import React from 'react'
import useMeasure from "react-use-measure";
import {animate, motion,  useMotionValue } from "framer-motion";
import { useEffect } from "react";


const logo2 = `${process.env.PUBLIC_URL}/images/logos/logo2.png`
const logo4 = `${process.env.PUBLIC_URL}/images/logos/logo4.png`
const logo6 = `${process.env.PUBLIC_URL}/images/logos/logo6.png`
const logo8 = `${process.env.PUBLIC_URL}/images/logos/logo8.png`
const logo9 = `${process.env.PUBLIC_URL}/images/logos/logo9.png`
const logo10 = `${process.env.PUBLIC_URL}/images/logos/logo10.png`


const parts = [
    {
        logo: logo2
    },
    {
        logo: logo4
    },
    {
        logo: logo6
    },
    {
        logo: logo8
    },
    {
        logo: logo9
    },
    {
        logo: logo10
    },
    
]

function Partners() {

    let [ref, {width}] = useMeasure();

    const xTranslation = useMotionValue(0);

    useEffect(() => {
        let controls;
        let finalPosition = -width - 8;

        controls = animate(xTranslation, [0, finalPosition], {
            ease: "linear",
            duration: 25,
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 0,
        });

        return controls.stop;
    },[xTranslation, width])

    
  return (
    <section className=' w-full flex flex-col bg-white py-10'>
        <div className="flex items-center justify-center mb-8">
          <div
            className="w-1/5 h-1"
            style={{
              background:
                "linear-gradient(to right, rgba(4,162,135,1), rgba(4,162,135,0))",
            }}
          ></div>
          <h2
            className="text-4xl font-bold mx-4 p-4"
            style={{
              background:
                "linear-gradient(0deg, rgba(4,162,135,1) 0%, rgba(38,145,215,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Partners
          </h2>
          <div
            className="w-1/5 h-1"
            style={{
              background:
                "linear-gradient(to left, rgba(4,162,135,1), rgba(4,162,135,0))",
            }}
          ></div>
        </div>

        <div className='my-5 py-8 flex justify-center rounded-md overflow-hidden'>
        <motion.div className='left-0 flex gap-10' ref={ref} style={{x: xTranslation}}>
            {[...parts,...parts,...parts,...parts,...parts].map((item,idx) => (
                <img src={item.logo} alt='partners' className=' w-[150px]' />
            ))}

        </motion.div>
        
        </div>


    </section>
  )
}

export default Partners