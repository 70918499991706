import React from 'react';
import './KeyFeatures.css';

const KeyFeatures = () => {
  return (
    <section className="key-features" id="key-features">
      <div className="container">
      <div className="heading-container">
        <div
          className="heading-line left"
          style={{
            background:
              "linear-gradient(to right, rgba(4,162,135,1), rgba(4,162,135,0))",
          }}
        ></div>
        <h2
          className="heading-text desktop-heading"
          style={{
            background:
              "linear-gradient(0deg, rgba(4,162,135,1) 0%, rgba(38,145,215,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Key Features
        </h2>
        <h2 className="heading-text mobile-heading"  style={{
            background:
              "linear-gradient(0deg, rgba(4,162,135,1) 0%, rgba(38,145,215,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}>Key Features</h2>
        <div
          className="heading-line right"
          style={{
            background:
              "linear-gradient(to left, rgba(4,162,135,1), rgba(4,162,135,0))",
          }}
        ></div>
      </div>
        <div className="grid-container">
          <div className="card first">
            <img src="/images/smartContractNew.png" alt="Smart Contracts Icon" className="card-icon h-28 w-28" />
            <h3 className='text-xl font-bold text-[#777777] pb-4 pt-4'>Smart Contracts</h3>
            <p className='text-[#848484] font-semibold'>Utilize smart contracts to automate and govern transactions. These self-executing contracts ensure that terms and conditions are met, providing a trustless and efficient way to trade tokenized assets.</p>
          </div>
          <div className="card second">
            <img src="images/fractionalNew.png" alt="Fractional Ownership Icon" className="card-icon h-28 w-28" />
            <h3 className='text-xl font-bold text-[#777777] pb-4 pt-4'>Fractional Ownership</h3>
            <p className='text-[#848484] font-semibold'>Tokenization allows for fractional ownership, making it possible to divide an asset into smaller, tradable units. This feature democratizes investment, allowing more people to own a piece of high-value assets.</p>
          </div>
          <div className="card middle">
            <img src="images/enhanceNew.png" alt="Enhanced Liquidity Icon" className="card-icon h-28 w-28" />
            <h3 className='text-xl font-bold text-[#777777] pb-4 pt-4'>Enhanced Liquidity</h3>
            <p className='text-[#848484] font-semibold'>Convert your physical assets into digital tokens to enhance liquidity. Tokenized assets can be easily traded on our marketplace, providing quicker and more efficient transactions compared to traditional methods.</p>
          </div>
          <div className="card fourth">
            <img src="images/portfolioNew.png" alt="Portfolio Diversification Icon" className="card-icon h-28 w-28" />
            <h3 className='text-xl font-bold text-[#777777] pb-4 pt-4'>Portfolio Diversification</h3>
            <p className='text-[#848484] font-semibold'>Diversify your investment portfolio by including tokenized real-world assets. With a range of asset classes available, from real estate to luxury goods, you can build a robust and varied portfolio.</p>
          </div>
          <div className="card fifth">
            <img src="images/globalNew.png" alt="Global Market Reach Icon" className="card-icon h-28 w-28" />
            <h3 className='text-xl font-bold text-[#777777] pb-4 pt-4'>Global Market Reach</h3>
            <p className='text-[#848484] font-semibold'>List your tokenized assets on a global platform, reaching potential buyers and investors from around the world. Our marketplace facilitates international trading, breaking down geographical barriers.</p>
          </div>
        </div>
        <button className="view-all-btn">View all Features</button>
      </div>
    </section>
  );
};

export default KeyFeatures;
