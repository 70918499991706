import { Skeleton } from '@mui/material'
import React from 'react'

function AnalyticsLoader() {
  return (
    <div className=" w-full flex flex-col gap-20">
      <div className=" flex w-full py-5 rounded-lg ">
        <Skeleton variant="rounded" width="100%" height={150} />
      </div>

      <div className="grid grid-cols-4 gap-8 w-full ">
        <div className="col-span-2  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className="col-span-2  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className="col-span-2  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className="col-span-2  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className="col-span-2  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className="col-span-1  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className="col-span-1  p-5 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width="100%" height={250} />
        </div>

        <div className=" col-span-2"></div>

        <div className="col-span-2  justify-self-end text-white p-8 rounded-lg flex flex-col gap-4 ">
        <Skeleton variant="rounded" width={300} height={250} />
        </div>
      </div>

      </div>
    
  )
}

export default AnalyticsLoader