import React, { useEffect, useState } from 'react';
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { MdCategory, MdKeyboardArrowLeft } from 'react-icons/md';
import { FaFilter } from 'react-icons/fa';
import { TbSortAscending } from 'react-icons/tb';
import { useParams, Link } from 'react-router-dom';
import PropCard from './components/PropCard';
import { get } from '../../Service/Apiservice';
import { config } from '../../config';
import NftLoader from '../../componets/loader/NftLoader';
import { BsBuildingExclamation } from 'react-icons/bs';

const catList = [
  { label: 'Tenanted', link: '#' },
  { label: 'Pre-Build', link: '#' },
];

const sortList = [
  { label: 'Low to High', link: '#' },
  { label: 'High to Low', link: '#' },
];

const filterList = [
  { label: 'Tenanted', link: '#' },
  { label: 'Pre-Build', link: '#' },
];



const Investments = () => {
  const { id } = useParams();
  const { getprojects } = config;
  const [data, setData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await get(`/v0/collection/nft-list?collectionId=${id}`, {
          headers: { 'Content-Type': 'application/json' },
        });
        console.log(response);
        setData(response.responseData[0]?.data || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }finally {
        setIsInitialLoading(false);
      }
  
    };
    fetchProject();

    const fetchCollection = async () => {
        try {
            const response = await get(getprojects, {
            headers: {
                "Content-Type": "application/json",
            },
            });
            console.log(response);
            setCollectionData(response.responseData[0]?.data);
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
        };
        fetchCollection();
  }, [id]);

  const DropdownBtn = ({ title, list, icon }) => (
    <Dropdown>
      <MenuButton className="flex font-semibold text-xs text-white bg-[#04a287] rounded-md py-2 h-fit px-4 items-center gap-1">
      <span className=' hidden md:inline-block'> {icon} </span> {title} 
      </MenuButton>
      <Menu className="bg-white rounded-md shadow-md">
      
        {list?.map((item, index) => (
          <MenuItem key={index} onClick={(cat) => filterItems(item.label)} className="px-8 py-2 hover:bg-primary-1-light rounded-md cursor-pointer">
            
              {item.label}
            
          </MenuItem>
        ))}
     
    </Menu>
    </Dropdown>
  );

  const filterItems = (cat) => {
    if (cat === "All") {
      setPropertyData(data);
    } else {
      const newItems = data.filter((newval) => newval?.PropertyType === cat);
      setPropertyData(newItems);
    }
  
  };
 



  const collectionItem = collectionData.find((data) => data?._id === id);

  return (
    <div className="w-full">
       {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen bg-white">
          <div class="loader"></div>
        </div>
      ) : (
        <div className="w-full flex px-10 py-5 flex-col">
          <Link to={`/collection`} className=" mb-5 flex items-center gap-4 group text-neutral-800 hover:text-neutral-500">
          <MdKeyboardArrowLeft className=" font-semibold group-hover:translate-x-1 transition-all" />
          <h1 className=" font-semibold ">back to collections</h1>
        </Link>
      <div className="w-full flex justify-between md:flex-row flex-col gap-6">
        <div className="flex gap-3 bg-white rounded-md w-fit">
          <button className="px-5 text-medium font-semibold rounded-md text-white py-2 bg-[#04a287]">
            Live ({data.length})
          </button>
          <button className="px-5 text-medium font-semibold rounded-md text-black py-2 hover:bg-neutral-200">
            Exited (0)
          </button>
          <button className="px-5 text-medium font-semibold rounded-md text-black py-2 hover:bg-neutral-200">
            Funded (0)
          </button>
        </div>
        <div className="flex gap-5">
          <DropdownBtn title="Select Category" list={catList} icon={<MdCategory />} />
          <DropdownBtn title="Sort" list={sortList} icon={<TbSortAscending />} />
          <DropdownBtn title="Filter" list={filterList} icon={<FaFilter />} />
        </div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 justify-items-center py-10">
      {isLoading && <NftLoader cards={6} />}
        {data.length === 0 && !isLoading ? (
          <div className=" flex flex-col col-span-3 items-center justify-center bg-white p-10 shadow-lg rounded-md">
          <BsBuildingExclamation className=" text-8xl text-primary-1" />
          <p className=" text-center text-wrap text-lg font-semibold text-neutral-600">
            No properties listed here yet!!
          </p>
        </div>
        ) : (
          data.slice().reverse().map((res, index) => (
            <PropCard
              key={res._id}
              data={res}
              collectionId={id}
              collectionImg={collectionItem?.collectionImage}
              endDate={collectionItem?.tillDate}
            />
          ))
        )}
      </div>
      </div>
      )}
    </div>
  );
};

export default Investments;
