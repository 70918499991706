import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBanner from './components/TopBanner';
import Sidebar from './components/Sidebar';


const Layout = () => {
  return (
    <div className=' w-full'>

        <div className=' w-full '>
            <TopBanner />
        </div>
        <div className=' flex  w-full'>
            <div className='flex justify-center w-1/3'>
                <Sidebar />
            </div>
            <div className=' w-2/3 pr-10'>
                <Outlet />
            </div>
        </div>
     
    </div>
  );
};

export default Layout;