import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';

import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";
import { FaStar } from 'react-icons/fa';
import { BsLinkedin } from 'react-icons/bs';
import { FaXTwitter } from 'react-icons/fa6';

const pic1 = `${process.env.PUBLIC_URL}/images/profile/pic4.jpg`
const pic2 = `${process.env.PUBLIC_URL}/images/profile/pic1.jpg`
const pic3 = `${process.env.PUBLIC_URL}/images/profile/pic5.jpg`

const items = [
    {
        name: 'Kathryn Murphy',
        role: 'Data Analyst',
        img: pic1,
        des: 'BitViraj has revolutionized my investment portfolio with tokenized real estate. The transparency and security of blockchain give me peace of mind, and fractional ownership has made high-value properties accessible. Their platform is user-friendly and efficient. I highly recommend BitViraj for anyone looking to diversify their investments in the real estate market.',
    },
    {
        name:'Shaun Michael',
        role: 'Builder',
        img: pic2,
        des: 'BitViraj has revolutionized my investment portfolio with tokenized real estate. The transparency and security of blockchain give me peace of mind, and fractional ownership has made high-value properties accessible. Their platform is user-friendly and efficient. I highly recommend BitViraj for anyone looking to diversify their investments in the real estate market.',
    },
    {
        name: 'Steve Harrington',
        role: 'Investment Banker',
        img: pic3,
        des: 'BitViraj has revolutionized my investment portfolio with tokenized real estate. The transparency and security of blockchain give me peace of mind, and fractional ownership has made high-value properties accessible. Their platform is user-friendly and efficient. I highly recommend BitViraj for anyone looking to diversify their investments in the real estate market.',
    },
]

function Testimonials() {

    const [swiper, setSwiper] = useState(null);

    const BannerCard = ({item}) => {

        return (
            <div className={`flex flex-col h-[500px] sm:h-[400px] p-10 w-full bg-white rounded-3xl`}>
                <div className=' w-full flex sm:flex-row flex-col gap-3 justify-between items-center'>
                    <div className=' flex items-center gap-3'>
                        <img src={item.img} alt='person' className='w-14 h-14 rounded-full' />
                        <div className='flex flex-col  '>
                            <h1 className=' text-xl font-bold text-neutral-600'>{item.name}</h1>
                            <h1 className=' text-lg font-semibold text-neutral-600'>{item.role}</h1>
                        </div>
                    </div>

                    <div className='flex gap-2 text-xl'>
                    <FaStar className=' text-primary-1' />
                    <FaStar className=' text-primary-1' />
                    <FaStar className=' text-primary-1' />
                    <FaStar className=' text-primary-1' />
                    <FaStar className=' text-primary-1' />
                    </div>
                </div>

                <div className=' w-full sm:mt-10 mt-5 h-[300px]'>
                    <p className=' text-base font-semibold text-neutral-600'>{item.des}</p>
                </div>

                <div className=' flex w-full self-end justify-center gap-5 mt-10'>
                    <BsLinkedin className=' text-[#0076B2] text-2xl' />
                    <FaXTwitter className=' text-2xl' />
                </div>
    
         
            </div>
        )
    }


  return (
    <section className='w-full mt-20 flex flex-col p-10 bg-[#31B29C] items-center'>
        <div className=' flex mt-5 w-full justify-center items-center gap-5 z-10'>
            <div className=' bg-white w-52 h-1'></div>
            <h1 className=' text-center lg:text-4xl text-2xl font-bold text-white'>Testimonials</h1>
            <div className=' bg-white w-52 h-1'></div>
        </div>

        <div className=' flex justify-around items-center'>

        <IoIosArrowDropleftCircle onClick={() => swiper?.slidePrev()} className='md:block hidden text-4xl text-white'/>

        <div className=' w-[400px] sm:w-[500px] lg:w-[900px] xl:w-[1000px] px-5 md:px-10 relative mt-10'>
        <Swiper
        spaceBetween={30}
        centeredSlides={true}
        effect={'fade'}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        onSwiper={setSwiper}
        
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        {items.map((d,i) => (
            <SwiperSlide>
                <BannerCard item={d} />
            </SwiperSlide>
        ))}

        
      </Swiper>
      
    
    </div>

    <IoIosArrowDroprightCircle onClick={() => swiper?.slideNext()} className=' md:block hidden text-4xl text-white' />


        </div>

        


    </section>
  )
}

export default Testimonials