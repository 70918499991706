import React, { useContext, useEffect, useRef, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { TbMoneybag } from "react-icons/tb";
import { TbPigMoney } from "react-icons/tb";
import { BsCreditCard2Front } from "react-icons/bs";
import "./css/Account.css";
import axios from "axios";
import profilePlaceholder from "../../assets/images/profile/pic2.jpg";
import { WalletInfoContext } from "../../hooks/useWalletInfo";

function Account() {
  const [imageData, setImageData] = useState({
    profileImage: "",
    walletAddress: "",
  });

  const [usdtBalance, setUsdtBalance] = useState("0");
  const [ogBalance, setOgBalance] = useState("0");
  const { walletInfo } = useContext(WalletInfoContext);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      axios
        .get(`${baseUrl}/v0/user/profile?email=${email}`)
        .then((response) => {
          const data = response.data.responseData;
          if (data) {
            setImageData({
              profileImage: data.profilePic || profilePlaceholder,
              walletAddress: data.walletAddress || "",
            });
          }
          setIsInitialLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    }
  }, []);

  useEffect(() => {
    const fetchUSDTBalance = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/v0/user/usdt-balance`,
          new URLSearchParams({ address: imageData.walletAddress }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setUsdtBalance(
          parseFloat(response.data.responseData).toFixed(2) || "0"
        );
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    };

    const fetchOGBalance = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/v0/user/og-balance`,
          new URLSearchParams({ address: imageData.walletAddress }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setOgBalance(parseFloat(response.data.responseData).toFixed(2) || "0");
      } catch (error) {
        console.error("Error fetching OG balance:", error);
      }
    };

    if (imageData.walletAddress) {
      fetchUSDTBalance();
      fetchOGBalance();
    }
  }, [imageData.walletAddress]);

  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [depositData, setDepositData] = useState([]);

  const token = localStorage.getItem("token");
  const apiDepositeUrl = `${baseUrl}/v0/user/getTransactionInfo`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiDepositeUrl, {
          headers: {
            accept: "*/*",
            token: token,
          },
        });

        setDepositData(response.data.responseData);
      } catch (error) {
        console.error("There was an error making the request:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const calculateInvestedAmount = () => {
      let total = 0;
      depositData.forEach((deposit) => {
        if (deposit.transactionStatus === "SUCCESS") {
          total += Number(deposit.price);
        }
      });
      setTotalInvestedAmount(total);
    };

    calculateInvestedAmount();
  }, [depositData]);

  const inputRef = useRef(null);

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand("copy");
    alert("Copied the text: " + inputRef.current.value);
  };

  const amountInfo = [
    {
      title: "Total Portfolio Value",
      val: walletInfo?.inramount,
      icon: <BsCreditCard2Front />,
    },
    {
      title: "Total Rental to date",
      val: "0.00",
      icon: <GrMoney />,
    },
    {
      title: "Current Month Rental Received",
      val: "0.00",
      icon: <TbMoneybag />,
    },
    {
      title: "Active Investments",
      val: totalInvestedAmount,
      icon: <TbPigMoney />,
    },
  ];

  return (
    <>
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div class="loader"></div>
        </div>
      ) : (
        <>
          <div className="w-full flex flex-col gap-12 p-20 acc-container">
            <div className="relative flex flex-col w-full px-10 py-5 bg-white shadow-lg bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 text-white rounded-lg">
              <div className="absolute top-0 left-0 bg-blue-500 text-white px-3 py-1 rounded-br-lg text-sm font-semibold">
                Testnet
              </div>
              <div className=" flex w-full justify-end">
                <h1 className=" text-xl italic">BlockMosaic</h1>
              </div>
              <div className=" flex mt-4 mb-8 w-full">
                <div className=" flex flex-col w-2/3 gap-6">
                  <h1 className=" text-2xl font-semibold">Wallet Address</h1>
                  {/* wallet address */}
                  <div className="w-full flex items-center">
                    <input
                      className=" bg-transparent w-[400px] focus:outline-none focus:border-none"
                      type="text"
                      name="name"
                      ref={inputRef}
                      value={imageData.walletAddress}
                      readOnly
                    />
                    <MdContentCopy
                      onClick={handleCopy}
                      className=" text-neutral-200 cursor-pointer  hover:text-black text-lg"
                    />
                  </div>

                  <div className="flex w-full ">
                    <div className=" flex flex-col gap-5  justify-center border-r border-white py-3 pl-6 w-1/3">
                      <h1 className=" text-xl font-semibold">USDT Value</h1>
                      <h1 className=" text-xl font-semibold">{usdtBalance}</h1>
                    </div>
                    <div className=" flex flex-col gap-5  justify-center py-3 pl-10 ">
                      <h1 className=" text-xl font-semibold">OG Value</h1>
                      <h1 className=" text-xl font-semibold">{ogBalance}</h1>
                    </div>
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${imageData.walletAddress}`}
                      className=" my-3 ml-10 w-[100px] h-[100px]"
                    />
                  </div>
                </div>

                <div className=" w-1/3 flex justify-center items-center">
                  <img
                    src={imageData.profileImage}
                    alt="profile pic"
                    className=" w-[200px] h-[200px] rounded-full"
                  />
                </div>
              </div>
              <div className=" w-full grid grid-cols-4 gap-3">
                {amountInfo.map((d, i) => (
                  <div
                    className=" h-[100px] p-2 flex justify-between bg-primary-1 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 shadow-xl col-span-1 rounded-xl"
                    key={i}
                  >
                    <div className=" flex flex-col">
                      <h1 className=" text-base text-wrap capitalize text-white">
                        {d.title}
                      </h1>
                      <span className=" text-base w-fit shadow-xl text-white font-semibold">
                        INR {d.val}
                      </span>
                    </div>
                    <div className=" text-white self-end text-xl">{d.icon}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Account;
