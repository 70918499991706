import React, { useEffect, useState } from "react";
import { Modal, Typography } from "@mui/material";
import axios from "axios";
import { useWalletInfo } from "../../../hooks/useWalletInfo";
import toast from "react-hot-toast";
import SpinLoader from "../../../componets/loader/SpinLoader";

const ShowFractionModal = ({ open, handleClose, data, selected }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=" w-96 h-96 flex flex-col  items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-lg bg-white">
        <h1 className="text-black font-bold">Fraction Details</h1>
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-4">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-white uppercase bg-primary-1">
              <tr>
                <th scope="col" className="py-3 px-6">
                  S.no
                </th>
                <th scope="col" className="py-3 px-6">
                  Owner Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {data[selected]?.noOfPurchaseUser &&
              data[selected]?.noOfPurchaseUser.length > 0 ? (
                data[selected].noOfPurchaseUser.map((fraction, i) => (
                  <tr key={i} className="bg-white border-b">
                    <td className="py-4 px-6">{i + 1}</td>
                    <td className={`py-4 px-6`}>
                      {fraction.purchasedByName
                        ? fraction.purchasedByName
                        : "No Data Found"}
                    </td>
                    <td className="py-4 px-6">{fraction.investPrice}</td>
                  </tr>
                ))
              ) : (
                <tr className="bg-white border-b">
                  <td colSpan="3" className="py-4 px-6 text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <button
          className="bg-primary-1 mb-4 mt-4 px-2 py-2 rounded text-white"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ShowFractionModal;
