import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import InfiniteScroll from "./InfiniteScroll";
import Globe from "../../components/magicui/globe";
import { get } from "../../Service/Apiservice";
import Partners from "./Partners";
import { Link } from "react-router-dom";
const words = ["Secure", "Seamless", "Revolutionary"];
const colors = ["#22ACCA", "#33FF57", "#04A287", "#FF33A8"];

export const HeroSection = () => {
  const [loaded, setLoaded] = useState(false);
  const [index, setIndex] = useState(0);
  const [nftList, setNftList] = useState([]);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await get(`v0/collection/nft-list`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log(response.responseData[0]?.data, "response");
        setNftList(response.responseData[0]?.data);
        // setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    fetchProject();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 4000); // Change word and color every 2 seconds

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const getRandomProgress = () => Math.floor(Math.random() * 101);
  
  const scrollToFeatures = () => {
    const featuresSection = document.getElementById("key-features");
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="py-4 md:py-16">
      <div className="mx-auto flex flex-wrap items-center px-4 flex-col gap-5 sm:gap-0 lg:flex-row md:flex-row">
  <div className="container mx-auto flex flex-col items-center text-center px-6">
    <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold text-[#4d4d4d]">
      Tokenization Made Simple through our{" "}
      <span style={{ color: colors[index] }}>{words[index]}</span>{" "}
      <br />
      Services.
    </h1>
    <p className="mt-4 text-xl sm:text-2xl text-[#808080] font-semibold">
      Unlocking the Future of Transactions
    </p>
    <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
    <Link
  onClick={() => window.open('https://api.whatsapp.com/send?phone=919699860925', '_blank', 'noopener noreferrer')}
>
  <button className="bg-[#04A287] text-white w-48 px-8 py-2 rounded-lg transition-all duration-300 ease-in-out hover:bg-[#038A6E] shadow-lg hover:shadow-md hover:border-transparent">
    Book a Demo
  </button>
</Link>
<button
  className="bg-transparent border text-[#04A287] w-48 px-8 py-2 rounded-lg transition-all duration-300 ease-in-out hover:bg-[#04A287] hover:text-white shadow-[0_0_5px_#04A287] hover:shadow-[0_0_10px_#04A287] hover:border-transparent"
  onClick={scrollToFeatures}
>
  View all Features
</button>

    </div>
  </div>

        <div
          className={`w-full sm:mb-3  md:w-1/2 px-4 flex  justify-end  herosection_item right ${
            loaded ? "loaded" : ""
          }`}
        >

        </div>
      </div>
      <div className="relative m-auto flex h-full w-full max-w-[32rem] items-center justify-center overflow-hidden  px-40 pb-40 pt-8 md:pb-60 ">
        <Globe className="top-0" />
      </div>
      <div className="w-full  h-724" style={{}}>
        {/* <div className='sm:flex hidden w-full justify-center'>
          <img
            className="  w-1/3 z-0 relative"
            src="/images/sphere.gif"
            alt="hero"
          />
        </div>  */}

        {/* <div className='w-full p-2 bg-white z-20 relative mt-0 sm:mt-[-180px]' > */}
        <div className="w-full p-2 bg-white z-20 relative mt-0">

        <Partners />
          {/* <InfiniteScroll nftList={nftList} /> */}
        </div>
        <div
          class="w-full bg-blue-500 rounded-b-full py-24 px-14 flex items-center flex-col gap-y-36 z-20 relative"
          style={{
            background:
              "linear-gradient(1360deg, rgba(4,162,135,1) 26%, rgba(38,145,215,1) 100%)",
          }}
        >
          <img
            src="/images/elements2.png"
            style={{
              width: 364,
              position: "absolute",
              left: 103,
              top: 3,
            }}
            alt="cross"
            className="block sm:hidden"
          />

          <p class="text-4xl text-center font-semibold text-white tracking-normal">
            DISCOVER AND UNLOCK <span className="text-primary-4">REWARDS</span>{" "}
            & <span className="text-primary-3">ENJOY</span> EXCLUSIVE PERKS
          </p>
          <div className="w-full flex justify-center gap-x-6 items-center">
            <div className="w-fit">
              <p className="text-lg font-normal text-white tracking-wide">
              <span className="sm:text-6xl 5xl text-primary-4">
                  <CountUp end={425} duration={10} />+
                </span>
                <br />
                Total Raised
              </p>
            </div>
            <div className="custom-vertical-line"></div>
            <div className="w-fit">
              <p className="text-lg font-normal text-white tracking-wide">
              <span className="sm:text-6xl 5xl text-primary-4">
                  <CountUp end={5} duration={10} />+
                </span>
                <br />
                Upcoming Sales
              </p>
            </div>
            <div className="custom-vertical-line"></div>
            <div className="w-fit">
              <p className="text-lg font-normal text-white tracking-wide">
              <span className="sm:text-6xl 5xl text-primary-4">
                  <CountUp end={10} duration={10} />+
                </span>
                <br />
                Trusted By
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
