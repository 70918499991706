import { Modal } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import "../css/BuilderDashboard.css";
import SpinLoader from "../../../componets/loader/SpinLoader";

function AddCollectionModal({ open, handleClose, refresh }) {
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [collectionFormData, setCollectionFormData] = useState({
    collectionImage: "",
    bannerImage: "",
    description: "",
    displayName: "",
    startDate: "",
    endDate: "",
    AttributeLocation: "",
  });

  const handleFileChange = (event, callback) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log(reader.result);

        callback(reader.result);
      };
    }
  };

  const handleCollectionChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      handleFileChange(e, (base64) => {
        setCollectionFormData({
          ...collectionFormData,
          [name]: base64,
        });
      });
    } else {
      setCollectionFormData({
        ...collectionFormData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${baseUrl}/v0/collection/addCollection`,
        collectionFormData,
        {
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
      setIsLoading(false);
      toast.success("Collection added successfully");
      handleClose();
      refresh();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=" w-[80%] h-[80%] flex flex-col  items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md shadow-lg bg-white">
        {isLoading && <SpinLoader />}

        <form onSubmit={handleSubmit} className=" w-full">
          <div className="grid p-8 grid-cols-4 bg-white rounded-xl  w-full gap-10">
            <h1 className="text-3xl col-span-4 text-primary-1 text-center font-semibold">
              Create Collection
            </h1>

            {/* collection Name */}
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-sm font-semibold text-heading ml-3">
                Collection Name
              </h1>
              <input
                className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                type="text"
                name="displayName"
                id="displayName"
                onChange={handleCollectionChange}
              />
            </div>

            {/* collection Image */}
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-sm font-semibold text-heading ml-3">
                Collection Image
              </h1>
              <input
                className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                type="file"
                name="collectionImage"
                id="collectionImage"
                onChange={handleCollectionChange}
              />
            </div>

            {/* banner Image */}
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-sm font-semibold text-heading ml-3">
                Banner Image
              </h1>
              <input
                className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                type="file"
                name="bannerImage"
                id="bannerImage"
                onChange={handleCollectionChange}
              />
            </div>

            {/* location */}
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-sm font-semibold text-heading ml-3">
                Attribute Location
              </h1>
              <input
                className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                type="text"
                name="AttributeLocation"
                id="AttributeLocation"
                onChange={handleCollectionChange}
              />
            </div>

            {/* Start Date */}
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-sm font-semibold text-heading ml-3">
                Start Date
              </h1>
              <input
                name="startDate"
                onChange={handleCollectionChange}
                type="datetime-local"
                id="startDate"
                className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
              />
            </div>

            {/* End Date */}
            <div className="col-span-2 flex flex-col gap-1">
              <h1 className="text-sm font-semibold text-heading ml-3">
                End Date
              </h1>
              <input
                name="endDate"
                onChange={handleCollectionChange}
                type="datetime-local"
                id="endDate"
                className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
              />
            </div>

            {/* Description */}
            <div className="col-span-4 flex flex-col">
              <h1 className="text-sm font-semibold text-heading">
                Description
              </h1>
              <textarea
                className="mt-1 pl-5 py-2 border border-neutral-400 focus:outline-none focus:border-emerald-400  text-heading rounded-md shadow-sm"
                name="description"
                id="description"
                onChange={handleCollectionChange}
              />
            </div>

            <div className=" col-span-4 flex  justify-end gap-5">
              <button
                className=" px-8 py-1 rounded-full font-semibold hover:bg-neutral-600 text-white bg-neutral-700"
                onClick={handleClose}
              >
                Discard
              </button>
              <button
                type="submit"
                className=" px-10 bg-primary-1 hover:scale-95 transition-all text-white py-2 rounded-full font-semibold"
              >
                Create Collection
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default AddCollectionModal;
