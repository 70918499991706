import React, { useState } from "react";
import { useEffect } from "react";
import { useTheme } from "../hooks/usetheme";
import Cards from "./Cards";
import { get } from "../Service/Apiservice";
import { config } from "../config";
import { Spin } from "antd";
import DetailPageLoader from "../componets/loader/DetailPageLoader";
const Projects = () => {
  const { theme } = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const { getprojects } = config;
  const [Data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const [showIndex, setShowIndex] = useState(null);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await get(getprojects, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(response);
        setData(response.responseData[0]?.data);
        setIsLoading(false)
      } catch (error) {
        console.log(error);
      }finally {
        setIsInitialLoading(false);
      }
    };
    fetchProject();
  }, []);

  const handleLoader = (params) => {
    setLoader(params);
  };

  return (
    <>
     {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen bg-white">
          <div class="loader"></div>
        </div>
      ) : (
        <>
      <div
        className={`justify-center items-center bg-white`}
      >
        <h1
          className={`  text-center text-gray-700  pt-6 font-inter text-3xl mb-6 font-semibold leading-9  ${
            theme === "light" ? "text-gray-500" : "text-white"
          }`}
        >
          Projects
        </h1>
        <div
          className={`button-container w-fit px-2 rounded-lg mx-auto mt-3 bg-gray-200 items-center gap-5 py-1 flex justify-center `}
        >
          <button
            className={`  p-3  ${
              theme === "light" ? "text-black" : "text-white"
            }  hi font-sans text-sm font-normal leading-4   ${
              activeTab === "all" ? "bg-white" : ""
            } rounded-lg `}
            onClick={() => setActiveTab("all")}
          >
            All Projects
          </button>
          <button
            className={` p-3  ${
              theme === "light" ? "text-black" : "text-white"
            } font-sans text-sm font-normal leading-4  ${
              activeTab === "active" ? "bg-white" : ""
            } rounded-lg `}
            onClick={() => setActiveTab("active")}
          >
            Active
          </button>
          <button
            className={` p-3   ${
              theme === "light" ? "text-black" : "text-white"
            }  font-sans text-sm font-normal leading-4   ${
              activeTab === "upcoming" ? "bg-white" : ""
            } rounded-lg `}
            onClick={() => setActiveTab("upcoming")}
          >
            Upcoming
          </button>
          <button
            className={` p-3  ${
              theme === "light" ? "text-black" : "text-white"
            } font-sans text-sm font-normal leading-4  ${
              activeTab === "ended" ? "bg-white" : ""
            } rounded-lg `}
            onClick={() => setActiveTab("ended")}
          >
            Ended
          </button>
        </div>
        <div
          className={`pl-2 float-right border-2 rounded-lg flex items-center mr-14 h-10 sm:mr-5  ${
            theme === "dark" ? "ce" : "cce"
          } `}
        >
          <img className="w-6 h-6" src="/images/sign.png" alt="" />
          <input
            type="text"
            className={`${
              theme === "dark" ? "bg-black text-white" : "bg-white text-black"
            }   outline-none  `}
            placeholder="Search by project name"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {isLoading && <DetailPageLoader />}
        {loader ? (
          <div className=" h-96 flex items-center justify-center w-full">
            <Spin size="large" style={{ color: "green" }} />
          </div>
        ) : (
          <div className=" flex flex-col px-14 py-9 gap-5 w-full">
            {activeTab === "all"
              ? Data.filter((data) =>
                  data?.displayName
                    ?.toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase())
                )?.map((res, index) => (
                  <Cards
                    data={res}
                    index={res._id}
                    cardIndex={index}
                    flip={showIndex}
                    setShowIndex={() => setShowIndex(index)}
                    indexValue={index}
                    handleloader={handleLoader}
                  />
                ))
              : Data.filter((data) =>
                  data?.displayName
                    ?.toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase())
                )
                  ?.filter(
                    (data) => data?.status?.toLocaleLowerCase() === activeTab
                  )
                  ?.map((res, index) => (
                    <Cards
                      data={res}
                      index={res?._id}
                      cardIndex={index}
                      flip={showIndex}
                      setShowIndex={() => setShowIndex(index)}
                      handleloader={handleLoader}
                    />
                  ))}
          </div>
        )}
      </div>
      </>
      )}
    </>
  );
};

export default Projects;
