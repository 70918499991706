import React, { useState } from "react";

function Staking1({
  activeNav,
  data,
  handleInputChange,
  showModal,
  theme,
  calculatedRewards,
  maturityDates,
  inputValues,
  handleInputClick,
  stakeTransaction
}) {
  const [cardState, setCardState] = useState(
    data.map(() => ({ isOpen: false }))
  );

  // Filter stake1 transactions
  const stake1Transactions = stakeTransaction.filter(
    (transaction) => transaction.stakingType === "stake1"
  );

  // Merge totalStakedAmount from stake1Transactions into data
  const updatedData = data.map((dataItem) => {
    const cleanPeriodTime = dataItem.periodTime.replace(/\s+/g, '');
    const stake1Transaction = stake1Transactions.find(
      (transaction) => 
        transaction.duration.replace(/\s+/g, '') === cleanPeriodTime
    );
    return {
      ...dataItem,
      totalStakedAmount: stake1Transaction ? stake1Transaction.totalStakedAmount : 0,
    };
  });

  const handleCardClick = (index) => {
    setCardState((prevState) =>
      prevState.map((card, i) =>
        i === index ? { ...card, isOpen: !card.isOpen } : card
      )
    );
  };

  return (
    <>
      {activeNav === 1 &&
        updatedData?.map((dataItem, index) => (
          <div
            className={`Stake-Card ${
              theme === "dark" ? "bg-gray-900" : "bg-[#D6EFD8]"
            } px-3 md:px-10 py-5 rounded-lg shadow-lg`}
            onClick={() => handleCardClick(index)}
            key={index}
          >
            <div
              className={`Stake-Main-Box ${
                theme === "dark" ? "bg-gray-900" : "bg-white-900"
              } flex justify-between w-full gap-10`}
            >
              <div className="Stake-Card-Left-Box w-1/4">
                <div className="Stake-Card-Left-Img-Box">
                  <img src="" alt="" />
                </div>
                <div className="Stake-Card-Left-Content-Box">
                  <p
                    className={`mb-5 md:text-base text-sm font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Stake OG
                  </p>
                  <p className="md:text-lg text-sm text-neutral-700 font-semibold">
                    Deposit OG to earn OG
                  </p>
                </div>
              </div>
              <div className="Stake-Card-Right-Box flex w-3/4 justify-between">
                <div>
                  <p
                    className={`mb-5 md:text-base text-sm font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Reward
                  </p>
                  <p className="md:text-xl text-base text-primary-1">{dataItem.reward}</p>
                </div>
                <div>
                  <p
                    className={`mb-5 md:text-base text-sm font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Period
                  </p>
                  <p className="md:text-xl text-base text-[#f19020]">
                    {dataItem.periodTime}
                  </p>
                </div>
                <div>
                  <p
                    className={`mb-5 md:text-base text-sm font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    My Stake
                  </p>
                  {/* Display totalStakedAmount */}
                  <p className="md:text-xl text-base">
                    {dataItem.totalStakedAmount} OG
                  </p>
                </div>
              </div>
            </div>
            {cardState[index].isOpen && (
              <div className="flex flex-wrap gap-3 justify-between items-center py-6 mb-4 px-5 rounded-lg bg-neutral-200 mt-5">
                <div className="flex gap-6">
                  <div>
                    <p
                      className={`mb-4 ${
                        theme === "light" ? "text-black" : "text-white"
                      } font-semibold`}
                    >
                      Reward Earned
                    </p>
                    <p className="font-semibold">Maturity Date</p>
                  </div>
                  <div>
                    <p
                      className={`mb-4 ${
                        theme === "light" ? "text-black" : "text-white"
                      }`}
                    >
                      {calculatedRewards[index]?.toFixed(2) || 0} OG
                    </p>
                    <p className={
                        theme === "light" ? "text-black" : "text-white"
                      }
                    >
                      {maturityDates[index] || "N/A"}
                    </p>
                  </div>
                </div>

                <div className="flex gap-4 items-center">
                  <input
                    type="number"
                    value={inputValues[index]}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    onClick={handleInputClick}
                    className="px-2 py-1 rounded"
                    placeholder="Enter stake amount"
                  />

                  <button
                    className={`px-2 py-2 md:text-base text-xs rounded text-white ${
                      inputValues[index]?.trim() === "" ||
                      inputValues[index] <= 0
                        ? "bg-gray-500 cursor-not-allowed"
                        : "bg-primary-1"
                    }`}
                    onClick={() => showModal(index, "stake1")}
                    disabled={
                      inputValues[index]?.trim() === "" ||
                      inputValues[index] <= 0
                    }
                  >
                    Stake
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
    </>
  );
}

export default Staking1;
