import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import axios from "axios";

export const WalletInfoContext = createContext();
export const useWalletInfo = () => useContext(WalletInfoContext);

export const WalletInfoProvider = ({ children }) => {
  const [walletInfo, setWalletInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetchWalletInfo = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/v0/user/getWalletInfo`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );

      setWalletInfo(response.data.responseData); // Setting the response data to the state
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWalletInfo();
    const interval = setInterval(fetchWalletInfo, 30000); // Poll every 30 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [fetchWalletInfo]);

  return (
    <WalletInfoContext.Provider
      value={{ walletInfo, loading, error, fetchWalletInfo }}
    >
      {children}
    </WalletInfoContext.Provider>
  );
};
