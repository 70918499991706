/* eslint no-unused-vars: 0 */
/*global window, requestAnimationFrame*/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const CurvedCarousel = ({
  childWidth = 180,
  curve = 50,
  spacing = 40,
  rotation = true,
  friction = 0.95,
  children,
  onSelect,
  dragging
}) => {
  const [state, setState] = useState({
    dragging: false,
    left: (children.length * -1) * (childWidth + spacing),
    velocity: 0,
    containerWidth: 0
  });

  const touchObject = useRef({});
  const containerRef = useRef(null);
  const currentChildren = useRef([]);
  const transitionRef = useRef(null);

  const _setPosition = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      containerWidth: containerRef.current.offsetWidth
    }));
  }, []);

  useEffect(() => {
    _setPosition();
    window.addEventListener('load', _setPosition);
    window.addEventListener('resize', _setPosition);
    _startRaf();

    return () => {
      window.removeEventListener('load', _setPosition);
      window.removeEventListener('resize', _setPosition);
    };
  }, [_setPosition]);

  const _childrenWithPositions = () => {
    let allChildren = [...children];
    let returnChildren = [];
  
    // Create clones for the circular effect at the start and end
    const cloneStart = allChildren.map((child, index) =>
      React.cloneElement(child, { key: 'start-clone' + index })
    );
  
    const cloneEnd = allChildren.map((child, index) =>
      React.cloneElement(child, { key: 'end-clone' + index })
    );
  
    returnChildren = [...cloneStart, ...allChildren, ...cloneEnd];
  
    returnChildren.forEach((child, index) => {
      let left = (state.left + (index * (childWidth + spacing)));
  
      const alpha = 360 / state.containerWidth * (state.containerWidth / 2);
      const R = state.containerWidth;
      const y = left * Math.cos(1);
      let degrees = y / Math.PI * (curve / 100);
      let top = (Math.abs(y) * (curve / 100) / Math.SQRT1_2);
      const exp = left / (childWidth + spacing);
      top = top * Math.abs(exp) * (Math.LN10 / 10);
  
      if (!rotation) {
        degrees = 0;
      }
  
      const style = {
        position: 'absolute',
        left: left + (state.containerWidth / 2) - (childWidth / 2),
        transform: `rotate(${degrees}deg)`,
        transformOrigin: 'bottom center',
        top: top,
        width: childWidth
      };
  
      returnChildren[index] = React.cloneElement(child, { style, key: index });
    });
  
    currentChildren.current = returnChildren;
  
    return returnChildren;
  };
  

  const _getMouseEvents = () => {
    if (dragging === false) {
      return {};
    }

    return {
      onMouseDown: e => {
        touchObject.current = {
          startX: e.pageX,
          startY: e.pageY,
          prevX: e.pageX,
          prevY: e.pageY,
          endX: e.pageX,
          endY: e.pageY,
          previousTime: new Date(),
          currentTime: new Date()
        };

        setState(prevState => ({
          ...prevState,
          dragging: true,
          velocity: 0
        }));
      },
      onMouseMove: e => {
        if (!state.dragging) {
          return;
        }

        if (!touchObject.current) {
          return;
        }

        const direction = _swipeDirection(
          touchObject.current.startX,
          e.clientX,
          touchObject.current.startY,
          e.clientY
        );

        if (direction !== 0) {
          e.preventDefault();
        }

        const length = Math.round(
          Math.sqrt(Math.pow(e.clientY - touchObject.current.startY, 2))
        );

        touchObject.current = {
          ...touchObject.current,
          prevX: touchObject.current.endX,
          prevY: touchObject.current.endY,
          endX: e.pageX,
          endY: e.pageY,
          length,
          direction
        };

        let left = state.left + (e.clientX - touchObject.current.prevX);

        if (left < (children.length * -2) * (childWidth + spacing)) {
          left = (children.length * -1) * (childWidth + spacing);
        }

        if (left > (children.length * -1) * (childWidth + spacing)) {
          left = (children.length * -2) * (childWidth + spacing);
        }

        setState(prevState => ({
          ...prevState,
          left
        }));
      },
      onMouseUp: e => {
        if (!state.dragging) {
          return;
        }

        if (!touchObject.current) {
          return;
        }

        const velocity =
          touchObject.current.prevX - touchObject.current.endX !== 0
            ? ((touchObject.current.prevX - touchObject.current.endX) /
                (touchObject.current.currentTime - touchObject.current.previousTime)) *
              12
            : 0;

        setState(
          prevState => ({
            ...prevState,
            dragging: false,
            velocity
          }),
          () => {
            _handleSwipe();
          }
        );
      },
      onMouseLeave: e => {
        if (!state.dragging) {
          return;
        }

        if (!touchObject.current) {
          return;
        }

        const velocity =
          touchObject.current.prevX - touchObject.current.endX !== 0
            ? ((touchObject.current.prevX - touchObject.current.endX) /
                (touchObject.current.currentTime - touchObject.current.previousTime)) *
              17
            : 0;

        setState(
          prevState => ({
            ...prevState,
            dragging: false,
            velocity
          }),
          () => {
            _handleSwipe();
          }
        );
      }
    };
  };

  const _swipeDirection = (x1, x2, y1, y2) => {
    const xDist = x1 - x2;
    const yDist = y1 - y2;
    const r = Math.atan2(yDist, xDist);
    let swipeAngle = Math.round((r * 180) / Math.PI);

    if (swipeAngle < 0) {
      swipeAngle = 360 - Math.abs(swipeAngle);
    }

    if ((swipeAngle <= 45 && swipeAngle >= 0) || (swipeAngle <= 360 && swipeAngle >= 315)) {
      return 1;
    }

    if (swipeAngle >= 135 && swipeAngle <= 225) {
      return -1;
    }

    if (dragging) {
      if (swipeAngle >= 35 && swipeAngle <= 135) {
        return 1;
      } else {
        return -1;
      }
    }

    return 0;
  };

  const _rafCb = useCallback(() => {
    if (state.dragging) {
      return;
    }

    let vel = state.velocity.toFixed(2) * friction;

    if (Math.abs(vel) < 0.1) {
      return;
    }

    let left = state.left - vel;

    if (left < (children.length * -2) * (childWidth + spacing)) {
      left = (children.length * -1) * (childWidth + spacing);
    }

    if (left > (children.length * -1) * (childWidth + spacing)) {
      left = (children.length * -2) * (childWidth + spacing);
    }

    setState(prevState => ({
      ...prevState,
      velocity: vel,
      left
    }));

    requestAnimationFrame(_rafCb);
  }, [state.dragging, state.velocity, state.left, children.length, childWidth, spacing, friction]);

  const _startRaf = () => {
    requestAnimationFrame(_rafCb);
  };

  const _handleSwipe = () => {
    touchObject.current = {};
    _startRaf();
  };

  // Slide to the left
  const slideLeft = () => {
    if (transitionRef.current) {
      clearInterval(transitionRef.current);
    }

    let start = state.left;
    let end = state.left - (childWidth + spacing);
    let step = (end - start) / 10; // Divide the distance into 10 steps
    let currentStep = 0;

    transitionRef.current = setInterval(() => {
      if (currentStep >= 10) {
        clearInterval(transitionRef.current);
        transitionRef.current = null;
        setState(prevState => ({
          ...prevState,
          left: end
        }));
        return;
      }

      setState(prevState => ({
        ...prevState,
        left: prevState.left + step
      }));

      currentStep += 1;
    }, 25); // 25ms per step
  };

  // Slide to the right
  const slideRight = () => {
    if (transitionRef.current) {
      clearInterval(transitionRef.current);
    }

    let start = state.left;
    let end = state.left + (childWidth + spacing);
    let step = (end - start) / 10; // Divide the distance into 10 steps
    let currentStep = 0;

    transitionRef.current = setInterval(() => {
      if (currentStep >= 10) {
        clearInterval(transitionRef.current);
        transitionRef.current = null;
        setState(prevState => ({
          ...prevState,
          left: end
        }));
        return;
      }

      setState(prevState => ({
        ...prevState,
        left: prevState.left + step
      }));

      currentStep += 1;
    }, 25); // 25ms per step
  };

  return (
    <div
      className="infinite-scroll"
      {..._getMouseEvents()}
      ref={containerRef}
    >
      <div style={{ height: 400 }} className='relative w-full h-full overflow-hidden'>
        {_childrenWithPositions()}
      </div>
      <div className='m-auto w-fit flex gap-20'>
      <button onClick={slideLeft} className='rounded-full text-white font-bold bg-primary-1 w-10 h-10'>{"<"}</button>
      <button onClick={slideRight} className='rounded-full text-white font-bold bg-primary-1 w-10 h-10'>{">"}</button>
      </div>
    </div>
  );
};

CurvedCarousel.propTypes = {
  childWidth: PropTypes.number,
  curve: PropTypes.number,
  spacing: PropTypes.number,
  rotation: PropTypes.bool,
  friction: PropTypes.number,
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
  dragging: PropTypes.bool
};

export default CurvedCarousel;
