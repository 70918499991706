import React, { useState } from "react";
import "./css/CreateCollection.css";
import { BsPlusCircleFill } from "react-icons/bs";
import { config } from "../../config";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SpinLoader from "../../componets/loader/SpinLoader";

function CreateCollection() {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [collectionFormData, setCollectionFormData] = useState({
    collectionImage: "",
    bannerImage: "",
    description: "",
    displayName: "",
    NumberOfProperties: 0,
    AttributeLocation: "",
    startDate: "",
    endDate: "",
  });
  const [nftForms, setNftForms] = useState([
    {
      PropertyPrice: 0,
      NumberOfFractions: 0,
      PropertyLocation: "",
      PropertyName: "",
      uri: "",
      coverImage: "",
      description: "",
      Attribute1: "",
      Attribute2: "",
      Attribute3: "",
      PropertyType: "",
    },
  ]);

  const handleFileChange = (event, callback) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log(reader.result);

        callback(reader.result);
      };
    }
  };

  const handleCollectionChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "file") {
      handleFileChange(e, (base64) => {
        setCollectionFormData({
          ...collectionFormData,
          [name]: base64,
        });
      });
    } else {
      setCollectionFormData({
        ...collectionFormData,
        [name]: value,
      });
    }
  };

  const handleNftChange = (index, e) => {
    const { name, value, type } = e.target;
    const newNftForms = nftForms.slice();
    if (type === "file") {
      handleFileChange(e, (base64) => {
        newNftForms[index] = { ...newNftForms[index], [name]: base64 };
        setNftForms(newNftForms);
      });
    } else {
      newNftForms[index] = { ...newNftForms[index], [name]: value };
      setNftForms(newNftForms);
    }
  };

  const addNftForm = () => {
    setNftForms([
      ...nftForms,
      {
        PropertyPrice: 0,
        NumberOfFractions: 0,
        PropertyLocation: "",
        PropertyName: "",
        uri: "",
        coverImage: "",
        description: "",
        Attribute1: "",
        Attribute2: "",
        Attribute3: "",
        PropertyType: "",
      },
    ]);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const fullFormData = {
      ...collectionFormData,
      startDate: formatDate(collectionFormData.startDate),
      endDate: formatDate(collectionFormData.endDate),
      nftArray: nftForms,
    };
    console.log(fullFormData);

    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${baseUrl}/v0/collection/addCollectionAndNft`,
        fullFormData,
        {
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
      setIsLoading(false);
      if (response.data.success) {
        toast.success("Collection and NFT added successfully");
        navigate("/collection");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-20 relative w-full flex flex-col bg-[#f4f4f4] gap-10">
      {isLoading && <SpinLoader />}

      <form onSubmit={handleSubmit}>
        <div className="grid p-20 grid-cols-4 bg-white rounded-xl shadow-md w-full gap-14">
          <h1 className="text-3xl col-span-4 text-primary-2 font-semibold">
            Create Collection
          </h1>

          <div className="relative col-span-2">
            <input
              name="displayName"
              onChange={handleCollectionChange}
              type="text"
              id="displayName"
              className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="displayName"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Display Name
            </label>
          </div>

          <div className="relative col-span-2">
            <input
              name="NumberOfProperties"
              onChange={handleCollectionChange}
              type="number"
              id="NumberOfProperties"
              className="block px-2.5 pb-2.5 pt-4 font-semibold w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="NumberOfProperties"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Number of Properties
            </label>
          </div>

          <div className="relative col-span-2">
            <input
              name="collectionImage"
              onChange={handleCollectionChange}
              type="file"
              id="collectionImage"
              className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="collectionImage"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Upload Collection Image
            </label>
          </div>

          <div className="relative col-span-2">
            <input
              name="bannerImage"
              onChange={handleCollectionChange}
              type="file"
              id="bannerImage"
              className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="bannerImage"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Upload Banner Image
            </label>
          </div>

          <div className="relative col-span-2">
            <input
              name="startDate"
              onChange={handleCollectionChange}
              type="datetime-local"
              id="startDate"
              className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="startDate"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Start Date
            </label>
          </div>

          <div className="relative col-span-2">
            <input
              name="endDate"
              onChange={handleCollectionChange}
              type="datetime-local"
              id="endDate"
              className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="endDate"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              End Date
            </label>
          </div>

          <div className="relative col-span-4">
            <textarea
              name="description"
              onChange={handleCollectionChange}
              id="description"
              className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="description"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Description
            </label>
          </div>

          <div className="relative col-span-4">
            <input
              name="AttributeLocation"
              onChange={handleCollectionChange}
              type="text"
              id="AttributeLocation"
              className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              htmlFor="AttributeLocation"
              className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Attribute Location
            </label>
          </div>

          {nftForms.map((nftForm, index) => (
            <div key={index} className="relative col-span-4 p-4  rounded-lg">
              <h2 className="text-2xl font-semibold mb-5 text-primary-2">
                Create NFT {index + 1}
              </h2>
              <div className="grid grid-cols-4 gap-12">
                <div className="relative col-span-2">
                  <input
                    name="PropertyName"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`PropertyName-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`PropertyName-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Name
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="PropertyLocation"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`PropertyLocation-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`PropertyLocation-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Location
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="PropertyPrice"
                    onChange={(e) => handleNftChange(index, e)}
                    type="number"
                    id={`PropertyPrice-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`PropertyPrice-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Price
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="NumberOfFractions"
                    onChange={(e) => handleNftChange(index, e)}
                    type="number"
                    id={`NumberOfFractions-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`NumberOfFractions-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Number of Fractions
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="coverImage"
                    onChange={(e) => handleNftChange(index, e)}
                    type="file"
                    id={`coverImage-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`coverImage-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Upload Cover Image
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="uri"
                    onChange={(e) => handleNftChange(index, e)}
                    type="file"
                    id={`uri-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`uri-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    URI
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="Attribute1"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`Attribute1-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`Attribute1-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Attribute 1
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="Attribute2"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`Attribute2-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`Attribute1-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Attribute 2
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="Attribute3"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`Attribute3-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`Attribute3-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Attribute 3
                  </label>
                </div>

                <div className="relative col-span-2">
                  <select
                    name="PropertyType"
                    onChange={(e) => handleNftChange(index, e)}
                    id={`PropertyType-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                  >
                    <option value="" disabled selected></option>
                    <option
                      className=" text-base option-styling"
                      value="Tenant"
                    >
                      Tenant
                    </option>
                    <option
                      className="text-base option-styling"
                      value="Pre-build"
                    >
                      Pre-build
                    </option>
                  </select>
                  <label
                    htmlFor={`PropertyType-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Type
                  </label>
                </div>

                <div className="relative col-span-4">
                  <textarea
                    name="description"
                    onChange={(e) => handleNftChange(index, e)}
                    id={`description-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`description-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Description
                  </label>
                </div>
              </div>
            </div>
          ))}

          <div className="col-span-4 flex justify-center">
            <button
              type="button"
              onClick={addNftForm}
              className="text-primary-2 flex items-center gap-2"
            >
              <BsPlusCircleFill className="text-6xl" />
            </button>
          </div>

          <div className="col-span-4">
            <button
              type="submit"
              className="w-full bg-primary-2 text-white py-2 rounded-lg font-semibold"
            >
              Create Collection
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateCollection;
