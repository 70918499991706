import React from 'react'
import Skeleton from '@mui/material/Skeleton'

function DetailPageLoader() {
  return (
    <div class="flex justify-center items-center h-screen bg-white p-5 ">
<div class="flex flex-row gap-2 items-center">
<h1 className=' text-green-500 font-semibold text-2xl mr-3'>Please Wait</h1>
  <div class="w-4 h-4 rounded-full bg-green-500 animate-bounce"></div>
  <div class="w-4 h-4 rounded-full bg-green-500 animate-bounce [animation-delay:-.3s]"></div>
  <div class="w-4 h-4 rounded-full bg-green-500 animate-bounce [animation-delay:-.5s]"></div>
 
</div>
</div>
  )
}

export default DetailPageLoader