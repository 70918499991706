import React, { useEffect, useState } from 'react';
import { LuBedDouble } from "react-icons/lu";
import { IoHeartCircleOutline } from "react-icons/io5";
import { LuHome } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import { Slider } from '@mui/material';
import { useLocationData } from '../../../hooks/useLocationData';
import { getCurrencyAbbreviation } from "country-currency-map";
import creatorPic from '../../../assets/images/profile/pic2.jpg'
import axios from 'axios';
import { IoIosStar, IoMdShare } from 'react-icons/io';

const PropCard = ({
  data,
  collectionId,
  collectionImg,
  endDate,
}) => {
  const navigate = useNavigate();
  const { locationData } = useLocationData();
  const [currencyCode, setCurrencyCode] = useState('INR');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [formattedPrice, setFormattedPrice] = useState(null);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const end = new Date(endDate);
    const difference = end - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const updateTimer = () => {
    setTimeLeft(calculateTimeLeft());
  };

  const timerId = setInterval(updateTimer, 1000);
  updateTimer();

  return () => clearInterval(timerId);
}, [endDate]);


  useEffect(() => {
    
    if (locationData) {
      const newCurrencyCode = getCurrencyAbbreviation(locationData.country);
      setCurrencyCode(newCurrencyCode);
    }
  }, [locationData]);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/inr.json');
        const rate = response.data['inr'][currencyCode.toLowerCase()];
        setExchangeRate(rate);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    if (currencyCode) {
      fetchRates();
    }
  }, [currencyCode]);

  useEffect(() => {
    if (exchangeRate && data?.price) {
      const price = (data.price * exchangeRate).toFixed(2);
      const countryCode = locationData?.country_code?.toUpperCase();
      let formatted;
      if(countryCode){
        formatted = new Intl.NumberFormat('en-' + countryCode, {
          currency: currencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(price);
      }

      setFormattedPrice(formatted);
    }
  }, [exchangeRate, data?.price, currencyCode, locationData]);

  const handleCardClick = (id) => {
    navigate(`/collection/${collectionId}/nft/${id}`);
  };

  return (
    <div className='w-[377px] h-fit rounded-xl pb-5 flex flex-col xl:scale-100 scale-90 bg-white shadow-[rgba(4,162,135,0.3)] shadow-lg'>
      <div className='w-full relative h-[290px]'>
        <img src={data?.coverImage} onClick={() => handleCardClick(data?._id)} alt="img" className='rounded-t-xl w-full h-[290px]' />
        <div className='z-10 w-[95%] items-center absolute top-4 left-2 justify-between  flex'>
          <div className=' bg-white rounded-lg shadow-md px-2 py-1 flex'>
            <img src={creatorPic} className=' w-7 h-7 rounded-full transition-all hover:z-20 hover:scale-105' />
            <img src={collectionImg} className=' w-7 h-7 -ml-3 rounded-full transition-all hover:z-20 hover:scale-105' />
          </div>
          <div className=' bg-white h-fit rounded-lg shadow-md p-1 items-center flex gap-1'>
            <IoHeartCircleOutline className=' cursor-pointer text-xl text-pink-300' />
            <span className='text-sm'>0</span>
          </div>    
        </div>
        <div className=' absolute bottom-3 w-full flex justify-center'>
          <div className=' rounded-lg py-1 px-2 flex border-2 shadow-md border-dashed border-white bg-black bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 '>
            <span className='text-2xl text-white font-semibold'>
            {`${timeLeft.days}d : ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`}
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full p-5 gap-2'>
        <div className='flex gap-3'>
          <h1 className='h-fit py-1 px-3 text-xs bg-[#04a287] rounded-full text-white'> {data?.Attribute1}</h1>
          <h1 className='flex items-center gap-1 h-fit py-1 px-3 text-xs bg-[#04a287] rounded-full text-white'><LuBedDouble /> {data?.Attribute2}</h1>
          <h1 className='flex items-center gap-1 h-fit py-1 px-3 text-xs bg-[#04a287] rounded-full text-white'><LuHome /> {data?.Attribute3}</h1>
        </div>
        <div className='flex flex-col w-full'>
          <h1 className='text-xl text-neutral-700 font-bold'>{currencyCode} {formattedPrice || data?.price}</h1>
          <h1 className='text-lg text-neutral-700 font-bold capitalize'>{data?.tokenName}</h1>
        </div>
        <div className="w-full">
          <details className="group">
            <summary className="flex justify-center items-center font-medium px-5 cursor-pointer list-none">
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <div className="mt-3 group-open:animate-fadeIn">
              {/* Expanded information */}
              <div className='flex flex-col w-full gap-5'>
                <div className='flex items-center '>
                  <span className='h-fit border-r-2 border-black pr-3 text-sm font-semibold'>{data?.Beds} beds</span>
                  <span className='h-fit border-r-2 border-black px-3 text-sm font-semibold'>{data?.ba} ba</span>
                  <span className='h-fit py-1 px-3 text-sm font-semibold'>{data?.sqft} sq ft</span>
                </div>
                <div className='flex flex-col w-full gap-2'>
                  <div className='flex justify-between'><h1 className='text-xs font-semibold'>Annual Rental Yield</h1> <span className='text-xs font-semibold'>{data?.AnnualRentalYield}%</span></div>
                  <div className='flex justify-between'><h1 className='text-xs font-semibold'>Net Rental Yield</h1> <span className='text-xs font-semibold'>{data?.NetRentalYield}%</span></div>
                  <div className='flex justify-between'><h1 className='text-xs font-semibold'>Annual Return</h1> <span className='text-xs font-semibold'>{data?.AnnualizedReturn}%</span></div>
                  <div className='flex justify-between'><h1 className='text-xs font-semibold'>Total Expected Return</h1> <span className='text-xs font-semibold'>{data?.TotalExpectedReturn}%</span></div>
                </div>
                <Slider
                  defaultValue={30}
                  sx={{
                    width: 250,
                    height: 8,
                    color: '#04a287',
                  }}
                />
                <div className='flex flex-col w-full gap-2'>
                  <div className='flex justify-between'><h1 className='text-xs font-semibold'>Property Price</h1> <span className='text-xs font-semibold'>{currencyCode} {formattedPrice || data?.price}</span></div>
                </div>
              </div>
            </div>
          </details>
        </div>
        <div className=' w-full flex items-center justify-between'>
          <button onClick={() => handleCardClick(data?._id)} className='flex gap-2 items-center px-5 py-2 text-base hover:scale-95 text-white bg-gradient-to-tr transition-all from-blue-400 to-green-400 from-1% to-99% font-semibold  rounded-lg'>View Details</button>
          <div className=' flex gap-2'>
            <IoMdShare className=' text-3xl text-primary-1 bg-primary-1 bg-opacity-30 hover:bg-opacity-100 hover:text-white rounded-full p-1' />
            <IoIosStar className=' text-3xl text-primary-1 bg-primary-1 bg-opacity-30 hover:bg-opacity-100 hover:text-white rounded-full p-1' />
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default PropCard;
