import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { FaRegUser } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { IoMdPin } from "react-icons/io";
import { IoWalletOutline } from "react-icons/io5";
import { ProfileContext } from "../../hooks/useProfileData";
import toast from "react-hot-toast";

function PersonalDetails() {
  const { setProfileData } = useContext(ProfileContext);

  const [formData, setFormData] = useState({
    walletAddress: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    postCode: "",
    city: "",
    country: "",
    profileImage: "",
    bannerImage: "",
    bio: "",
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const fileInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const inputRef = useRef(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      axios
        .get(`${baseUrl}/v0/user/profile?email=${email}`)
        .then((response) => {
          const data = response.data.responseData;
          if (data) {
            setFormData({
              walletAddress: data.walletAddress || "",
              firstName: data.firstName || "",
              lastName: data.lastName || "",
              phoneNumber: data.mobileNumber || "",
              email: data.email || "",
              address: data.address || "",
              postCode: data.postCode || "",
              city: data.city || "",
              country: data.country || "",
              profileImage: data.profilePic || "",
              bannerImage: data.bannerImage || "",
              bio: data.bio || "",
            });
            if (data.profilePic) {
              setImagePreview(data.profilePic);
            }
            if (data.bannerImage) {
              setBannerPreview(data.bannerImage);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    }
  }, []);

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand("copy");
    alert("Copied the text: " + inputRef.current.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (imageType === "profileImage") {
          setImagePreview(reader.result);
        } else if (imageType === "bannerImage") {
          setBannerPreview(reader.result);
        }
        setFormData({
          ...formData,
          [imageType]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (imageType) => {
    if (imageType === "profileImage") {
      fileInputRef.current.click();
    } else if (imageType === "bannerImage") {
      bannerInputRef.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/v0/user/add-profile`,
        formData
      );
      console.log(response);
      setProfileData({
        profileImage: formData.profileImage,
        bannerImage: formData.bannerImage,
        walletAddress: formData.walletAddress,
      });
      toast.success("Profile updated successfully !!");
    } catch (error) {
      console.error("Error updating profile:", error);
      console.log("Failed to update profile.");
    }
  };

  return (
    <div className="w-full px-5 mb-10 bg-white">
      <h1 className="text-heading text-xl font-semibold mb-5">Welcome!</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-4 gap-x-5 grid-rows-11">
          {/* First Name */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">First Name</h1>
            <input
              className="mt-1 pl-5 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
              type="text"
              name="firstName"
              value={formData.firstName}
              placeholder="First Name"
              onChange={handleChange}
            />
          </div>

          {/* Profile Image */}
          <div className="col-span-1 row-span-2 flex flex-col items-center">
            <h1 className="text-medium font-semibold text-heading">
              Profile Image
            </h1>
            <input
              ref={fileInputRef}
              className="hidden"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, "profileImage")}
            />
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Profile Preview"
                onClick={() => triggerFileInput("profileImage")}
                className="mt-4 w-24 h-24 rounded-full object-cover"
              />
            ) : (
              <div
                className="mt-4 w-24 h-24 rounded-full bg-neutral-200 flex items-center justify-center cursor-pointer"
                onClick={() => triggerFileInput("profileImage")}
              >
                Upload
              </div>
            )}
          </div>

          {/* Banner Image */}
          <div className="col-span-1 row-span-2 flex flex-col items-center">
            <h1 className="text-medium font-semibold text-heading">
              Banner Image
            </h1>
            <input
              ref={bannerInputRef}
              className="hidden"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e, "bannerImage")}
            />
            {bannerPreview ? (
              <img
                src={bannerPreview}
                alt="Banner Preview"
                onClick={() => triggerFileInput("bannerImage")}
                className="mt-4 w-24 h-24 rounded-full object-cover"
              />
            ) : (
              <div
                className="mt-4 w-24 h-24 rounded-full bg-neutral-200 flex items-center justify-center cursor-pointer"
                onClick={() => triggerFileInput("bannerImage")}
              >
                Upload
              </div>
            )}
          </div>

          {/* Last Name */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Last Name</h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="text"
                name="lastName"
                value={formData.lastName}
                placeholder="Last Name"
                onChange={handleChange}
              />
              <FaRegUser className="absolute left-2 top-1/2 -translate-y-1/2 text-lg" />
            </div>
          </div>

          {/* Email */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Email</h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="email"
                name="email"
                value={formData.email}
                placeholder="Email"
                onChange={handleChange}
              />
              <FiMail className="absolute left-2 top-1/2 -translate-y-1/2 text-lg" />
            </div>
          </div>

          {/* Wallet Address */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">
              Wallet Address
            </h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="text"
                name="walletAddress"
                value={formData.walletAddress}
                placeholder="Your Wallet Address"
                onChange={handleChange}
              />
              <IoWalletOutline className="absolute left-2 top-1/2 -translate-y-1/2 text-lg" />
            </div>
          </div>

          {/* Phone Number */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Phone</h1>
            <input
              className="mt-1 pl-5 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              placeholder="Phone Number"
              onChange={handleChange}
            />
          </div>

          {/* Postal Code */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Postal Code</h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="text"
                name="postCode"
                value={formData.postCode}
                placeholder="Postal Code"
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Address */}
          <div className="col-span-4 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Address</h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="text"
                name="address"
                value={formData.address}
                placeholder="Address"
                onChange={handleChange}
              />
              <IoMdPin className="absolute left-2 top-1/2 -translate-y-1/2 text-lg" />
            </div>
          </div>

          {/* City */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">City</h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="text"
                name="city"
                value={formData.city}
                placeholder="City"
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Country */}
          <div className="col-span-2 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Country</h1>
            <div className="relative w-full">
              <input
                className="mt-1 w-full pl-10 py-2 bg-neutral-100 text-heading rounded-full shadow-sm"
                type="text"
                name="country"
                value={formData.country}
                placeholder="Country"
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Bio */}
          <div className="col-span-4 flex flex-col">
            <h1 className="text-sm font-semibold text-heading">Bio</h1>
            <textarea
              className="mt-1 pl-5 py-2 bg-neutral-100 text-heading rounded-md shadow-sm"
              name="bio"
              value={formData.bio}
              placeholder="Tell us about yourself"
              onChange={handleChange}
              rows="4"
            />
          </div>

          {/* Save Button */}
          <div className="col-span-1 col-start-4 place-self-end">
            <button
              type="submit"
              className="font-semibold rounded-sm border-none text-white py-2 px-8 bg-gradient-to-tr from-blue-400 to-green-400 border border-transparent transform hover:scale-95 hover:border-white transition-transform duration-3000 ease-in-out"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PersonalDetails;
