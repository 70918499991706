import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./componets/header/header";
import Footer from "./componets/footer/footer";
import { HomePage } from "./pages/homepagenew/homePage";
import { Trade } from "./pages/trade";
import { useTheme } from "./hooks/usetheme";
import { Toaster } from "react-hot-toast";
import Projects from "./pages/Projects";
import Investments from "./pages/investments/Investments";
import PropertyDetail from "./pages/propertyDetailsPage/PropertyDetail";
import Analytics from "./pages/dashboard/Analytics";
import PersonalDetails from "./pages/profile/PersonalDetails";
import Account from "./pages/profile/Account";
import CreateCollection from "./pages/createcollection/CreateCollection";
import CollectionPage from "./pages/collectionpage/CollectionPage";
import Layout from "./pages/profile/Layout";
// import EditCollection from "./pages/editcollection/EditCollection";
import { usePosition } from "use-position";
import axios from "axios";
import { useLocationData } from "./hooks/useLocationData";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import PrivateRoute from "./componets/PrivateRoute"; // Import the PrivateRoute component
import LandingPage from "./pages/landingpage/landingPage";
import ProjectDetail from "./pages/projectdetail/ProjectDetail";
import BuilderDashboard from "./pages/builderdashboard/BuilderDashboard";
import BuilderAddNFT from "./pages/builderdashboard/BuilderAddNFT";
import AdminDashboard from "./pages/admindashboard/AdminDashboard";
import VerifyNft from "./pages/admindashboard/VerifyNft";
import EditCollection from "./pages/admindashboard/components/EditCollection";
import EditNft from "./pages/admindashboard/components/EditNft";
import Storyline from "./pages/propertyDetailsPage/Storyline";
import ScrollToTop from "./componets/ScrollToTop";
import TransactionDetails from "./pages/transactiondetails/TransactionDetails";
import ExploreBlogs from "./pages/homepagenew/blogs/ExploreBlogs";
import BlogsDetail from "./pages/homepagenew/blogs/BlogsDetail";
import BuilderNft from "./pages/builderdashboard/BuilderNft";


function App() {
  const { theme } = useTheme();
  const { setLocationData } = useLocationData();
  const watch = true;
  const {
    latitude,
    longitude,
  } = usePosition(watch, { enableHighAccuracy: true });

  useEffect(() => {
    const fetchData = async () => {
      if (latitude && longitude) {
        try {
          const response = await axios.get(
            'https://api.opencagedata.com/geocode/v1/json',
            {
              params: {
                q: `${latitude},${longitude}`,
                key: '7b8fa2edeb54476da1a825e0b7c0d62e',
              },
            }
          );
          setLocationData(response.data.results[0].components);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchData();
  }, [latitude, longitude, setLocationData]);

  return (
    <BrowserRouter>
      <div className={theme === "dark" ? "bg-gray-900" : ""}>
        <Header />
        <Toaster />
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<ExploreBlogs />} />
          <Route path="/blogs/:id" element={<BlogsDetail />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:projectId" element={<ProjectDetail />} />
          <Route path="/dashboard" element={<PrivateRoute element={<LandingPage/>} />}/>
          {/* builder dashboard */}
          <Route path="/builderdashboard" element={<BuilderDashboard />}/>
          <Route path="/builderaddnft/:id" element={<BuilderAddNFT />}/>
          <Route path="/buildernft/:collectionId" element={<BuilderNft />} />
          {/* admin dashboard */}
          <Route path="/admindashboard" element={<AdminDashboard />}/>   
          <Route path="/verifynft/:collectionId" element={<VerifyNft />} />
          <Route path="/editcollection/:collectionId" element={<EditCollection />} />
          <Route path="/collection/:collectionId/editnft/:nftId" element={<EditNft />} />
          <Route path="/transactiondetails" element={<TransactionDetails />} />


          <Route path="/trade" element={<PrivateRoute element={<Trade />} />} />
          <Route path="/investments/:id" element={<Investments />} />
          <Route path="/analytics" element={<PrivateRoute element={<Analytics />} />} />
          <Route path="/createcollection" element={<PrivateRoute element={<CreateCollection />} />} />
          {/* <Route path="/editcollection" element={<PrivateRoute element={<EditCollection />} />} /> */}
          {/* <Route path="/collection" element={<PrivateRoute element={<CollectionPage />} />} /> */}
          <Route path="/collection" element={ <CollectionPage />} />

          <Route path="/collection/:collectionId/nft/:nftId" element={<PropertyDetail />} />
          <Route path="/storyline" element={<PrivateRoute element={<Storyline />} />} />
          <Route path="/profile" element={<PrivateRoute element={<Layout />} />}>
            <Route index element={<Account />} />
            <Route path="personal-info" element={<PrivateRoute element={<PersonalDetails />} />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
