import React, { useState, useEffect } from "react";
import { MdAddToPhotos } from "react-icons/md";
import prop1 from "../../assets/images/property/prop1.jpeg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiFolderAddFill } from "react-icons/ri";
import axios from "axios";
import { Link } from "react-router-dom";
import AddCollectionModal from "../builderdashboard/components/AddCollectionModal";
import toast from "react-hot-toast";
import "./admin.css";

function AdminDashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [collectionToUnpublish, setCollectionToUnpublish] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingStates, setLoadingStates] = useState({});
  const collectionsPerPage = 5; // Number of collections per page
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userType = localStorage.getItem("userType");
  const [isLoading, setIsLoading] = useState(false);

  const fetchProject = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/v0/collection/collection-list?verify=false`,
        {
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        }
      );
      setData(response.data.responseData[0].data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleVerify = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    const token = localStorage.getItem("token");
    const data = {
      type: "collection",
      collectionId: id,
      verifyStatus: true,
    };
    const response = await axios.post(
      `${baseUrl}/v0/collectionAndNft/verify`,
      data,
      {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.success) {
      toast.success("Verified Successfully!");
      fetchProject();
    } else {
      toast.error("Verification failed!");
    }
    setLoadingStates((prev) => ({ ...prev, [id]: false }));
  };

  const handleUnpublish = (id) => {
    setCollectionToUnpublish(id);
    setShowUnpublishModal(true);
  };

  const confirmUnpublish = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const data = {
      type: "collection",
      collectionId: collectionToUnpublish,
      verifyStatus: false,
    };
    const response = await axios.post(
      `${baseUrl}/v0/collectionAndNft/verify`,
      data,
      {
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.success) {
      toast.success("Unpublished Successfully!");
      fetchProject();
      setShowUnpublishModal(false);
      setCollectionToUnpublish(null);
    } else {
      toast.error("Failed to unpublish the collection");
    }
    setIsLoading(false);
  };

  // Logic for displaying collections with pagination
  const indexOfLastCollection = currentPage * collectionsPerPage;
  const indexOfFirstCollection = indexOfLastCollection - collectionsPerPage;
  const currentCollections = data.slice(indexOfFirstCollection, indexOfLastCollection);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col p-10 bg-white">
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div class="loader"></div>
        </div>
      ) : (
        <>
          <h1 className="text-3xl text-neutral-800 font-semibold text-center">
            Admin Dashboard
          </h1>
          <div className="flex p-1 items-center bg-[#dadada] rounded-full self-end">
            <button className="px-6 py-2 text-white text-xs font-semibold bg-[#4e4d4d] rounded-full">
              Properties
            </button>
            <button className="px-6 py-2 text-[#4e4d4d] font-semibold text-xs rounded-full">
              Analytics
            </button>
          </div>

          <div className="flex flex-col h-screen overflow-y-auto border border-[#a7a7a7] mt-10 rounded-md">
            <div className="sticky top-0 left-0 bg-white flex justify-between p-5 border-b border-[#a7a7a7]">
              <h1 className="text-xl font-semibold text-neutral-800">
                {data?.length} {data?.length > 1 ? "Collections" : "Collection"}
              </h1>
              <button
                onClick={handleOpenModal}
                className="overflow-hidden px-6 py-2 bg-[#4e4d4d] text-white border-none rounded-full text-sm font-bold cursor-pointer relative z-10 group"
              >
                Add Collection
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-primary-1-light rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span className="absolute w-40 h-32 -top-8 -left-2 bg-primary-1 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2 left-16 z-10">
                  <MdAddToPhotos className="text-xl" />
                </span>
              </button>

              <AddCollectionModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                refresh={fetchProject}
              />
            </div>

            <div className="flex flex-col h-full w-full gap-5 px-8 py-5">
              {data.length === 0 ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <RiFolderAddFill className="text-8xl text-primary-1" />
                  <p className="w-[500px] text-center text-wrap text-lg font-semibold text-neutral-600">
                    No Collections here, click on Add Collection button to
                    create your first collection{" "}
                  </p>
                </div>
              ) : (
                currentCollections.map((d, i) => (
                  <div
                    className="flex px-5 py-3 items-center justify-between border border-[#a7a7a7] rounded-md shadow-md"
                    key={i}
                  >
                    <img
                      src={d?.collectionImage || prop1}
                      alt="collection"
                      className="w-16 h-16 rounded-md"
                    />
                    <h1 className="w-64 text-lg text-neutral-700 font-bold text-wrap">
                      {d?.displayName}
                    </h1>
                    <div className="flex flex-col gap-2 items-center">
                      <h1 className="text-sm text-neutral-500 font-semibold">
                        Total Properties
                      </h1>
                      <h1 className="text-lg text-neutral-700 font-bold">
                        {d?.placeNftCount}
                      </h1>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                      <h1 className="text-sm text-neutral-500 font-semibold">
                        Pending Properties
                      </h1>
                      <h1 className="text-lg text-neutral-700 font-bold">
                        {d?.pendingNft}
                      </h1>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                      <h1 className="text-sm text-neutral-500 font-semibold">
                        Admin Verified
                      </h1>
                      <h1 className="text-2xl text-primary-1 font-bold">
                        {d?.isVerify ? (
                          <IoIosCheckmarkCircle />
                        ) : (
                          <button
                            onClick={() => handleVerify(d._id)}
                            className="bg-[#04A287] text-white px-2 py-1 rounded-md text-sm"
                            disabled={loadingStates[d._id]}
                          >
                            {loadingStates[d._id] ? "Processing..." : "Verify"}
                          </button>
                        )}
                      </h1>
                    </div>
                    <Link key={d?._id} to={`/verifynft/${d?._id}`}>
                      <span className="px-4 py-2 text-white font-semibold rounded-md bg-gradient-to-tr from-[#3b9183] to-[#04a287]">
                        View Details
                      </span>
                    </Link>
                    <div className="relative">
                      <button className="text-neutral-800 peer focus:text-gray-200 transition-all duration-200">
                        <BsThreeDotsVertical className="text-3xl" />
                      </button>
                      <div
                        className='w-48 absolute top-5 right-2 z-10 shadow-lg
                        after:content-[""] after:inline-block after:absolute after:top-0 after:bg-white
                        after:w-full after:h-full after:-z-20  after:rounded-lg
                        peer-focus:top-10 peer-focus:opacity-100 peer-focus:visible 
                        transition-all duration-300 invisible  opacity-0'
                      >
                        <ul className="flex flex-col">
                          {userType === "Creator" && (
                            <Link to={`/builderaddnft/${d.id}`}>
                              <li className="cursor-pointer px-3 py-3 font-semibold hover:bg-primary-1 hover:text-white rounded-md hover:opacity-90 text-neutral-800 text-sm">
                                Add Property
                              </li>
                            </Link>
                          )}
                          {d.isVerify && (
                            <li
                              onClick={() => handleUnpublish(d._id)}
                              className="cursor-pointer px-3 py-3 font-semibold hover:bg-primary-1 hover:text-white rounded-md hover:opacity-90 text-neutral-800 text-sm"
                            >
                              Unpublish Collection
                            </li>
                          )}

                          <Link to={`/editcollection/${d.id}`}>
                            <li className="cursor-pointer px-3 py-3 font-semibold hover:bg-primary-1 hover:text-white rounded-md hover:opacity-90 text-neutral-800 text-sm">
                              Edit Collection Info
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {showUnpublishModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
              <div className="bg-white rounded-lg p-8 m-4 lg:max-w-96 sm:max-w-xs max-h-full text-center">
                <div className="mb-4">
                  <img
                    src="images/warning.png"
                    alt="Warning Icon"
                    className="w-20 h-20 mx-auto"
                  />
                  <h2 className="text-xl font-semibold mb-2">
                    Are you sure you want to unpublish the collection?
                  </h2>
                  <p className="text-gray-600">
                    Your collection data will be removed from the marketplace
                  </p>
                </div>
                <div className="flex flex-col space-y-4 mt-6">
                  <button
                    onClick={confirmUnpublish}
                    className="bg-[#666666] text-white px-20 py-1 rounded-full text-nowrap"
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Unpublish Collection"}
                  </button>
                  <button
                    onClick={() => setShowUnpublishModal(false)}
                    className="bg-inherit text-gray-500 px-4 py-2 rounded-md font-semibold"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Pagination */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: Math.ceil(data.length / collectionsPerPage) }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`px-3 py-1 border rounded-md mx-1 ${
                  currentPage === index + 1 ? "bg-[#04A287] text-white" : "bg-white text-[#4e4d4d]"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AdminDashboard;
