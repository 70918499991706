import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MetaMaskContextProvider } from "./hooks/useMetamask";
import { ThemeProvider } from "./hooks/usetheme";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider,Chain } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { polygonMumbai} from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { LocationDataProvider } from "./hooks/useLocationData";
import { ProfileProvider } from "./hooks/useProfileData";
import { AuthProvider } from "./hooks/useAuth";
import { WalletInfoProvider } from "./hooks/useWalletInfo";
const Amoy= {
  id: 80002,
  name: 'amoy',
  iconUrl: '',
  iconBackground: '#fff',
  nativeCurrency: { name: 'amoy', symbol: 'matic', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://polygon-amoy.drpc.org'] },
  } ,
  blockExplorers: {
    default: { name: 'oklink', url: 'https://www.oklink.com/amoy'},
  }
} 
const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains: [Amoy],
  ssr: true, // If your dApp uses server side rendering (SSR)
});
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
    <ProfileProvider>
      <WalletInfoProvider>
    <MetaMaskContextProvider>
    <LocationDataProvider>
      <ThemeProvider>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <App />
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </ThemeProvider>
      </LocationDataProvider>
    </MetaMaskContextProvider>
    </WalletInfoProvider>
    </ProfileProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
