import React from 'react'


const icon1 = `${process.env.PUBLIC_URL}/images/icons/analysis.png`
const icon2 = `${process.env.PUBLIC_URL}/images/icons/listing.png`
const icon3 = `${process.env.PUBLIC_URL}/images/icons/financial.png`
const icon4 = `${process.env.PUBLIC_URL}/images/icons/key.png`

const cardInfo = [
    {
        id: '01',
        icon: icon1,
        title: 'Asset Evaluation',
        content: 'The tokenization process starts with a thorough asset evaluation. Our experts assess the property value based on location, market trends, and condition. This ensures accurate valuation, forming the basis for creating digital tokens. Each token represents a precise share of the property value, offering transparent and fair investment opportunities.',
    },
    {
        id: '02',
        icon: icon2,
        title: 'Tokenization Process',
        content: 'We start with asset evaluation and legal checks. Next, we create blockchain-based tokens representing fractional ownership. These tokens are listed on our platform, enabling easy buying and selling. Each transaction is transparent, secure, and recorded on an immutable ledger.',
    },
    {
        id: '03',
        icon: icon3,
        title: 'Listing and Trading',
        content: 'Once tokenized, assets are listed on our platform, making them available for trading. Investors can easily buy and sell tokens, accessing real-time market data. Each transaction is secure, transparent, and recorded on the blockchain, ensuring trust and efficiency in every trade.',
    },
    {
        id: '04',
        icon: icon4,
        title: 'Ownership & Management',
        content: 'Owning tokenized assets on BitViraj grants you fractional ownership secured by blockchain. Manage your investments through our intuitive platform, track property performance, and receive dividends. Enjoy seamless and transparent ownership with automated processes and secure transactions.',
    },
]

function WorkCard() {
  return (
    <section className='  w-full flex flex-col p-10 lg:bg-gradient-to-t shadow-lg lg:from-white lg:to-white bg-gradient-to-br from-[#2691D7] via-[#04A287] to-[#04A287]  relative'>
        <div className='w-full hidden lg:block h-1/2 bg-gradient-to-br from-[#2691D7] via-[#04A287] to-[#04A287] absolute top-0 left-0'></div>
        <div className=' flex w-full justify-center items-center gap-5 z-10'>
            <div className=' bg-white w-52 h-1'></div>
            <h1 className=' text-center lg:text-5xl text-3xl font-bold text-white'>How it Works?</h1>
            <div className=' bg-white w-52 h-1'></div>
        </div>

        <div className=' mt-20 z-10 flex justify-center gap-10 flex-wrap'>

            {cardInfo.map((d, i) => (

                <div className=' relative w-[300px] h-[400px] justify-center bg-white rounded-lg flex flex-col items-center shadow-md p-5 '>
                <div className='absolute -top-10 -translate-x-1/2 left-1/2 z-20 bg-white p-3 rounded-full '>
                <h1 className='text-primary-1 text-xl font-semibold shadow-lg px-4 py-3 rounded-full'>{d.id}</h1>
                </div>
                <img src={d.icon} alt='asset' />
                <h2 className='text-xl font-bold text-center mt-5 text-neutral-600'>{d.title}</h2>
                <p className='text-gray-500 font-semibold text-sm mt-5 lg:text-justify md:text-justify sm:text-center'>{d.content}</p>
                </div>
            ))}
        
        </div>

        
        
    </section>
  )
}

export default WorkCard