import React from "react";
import { AreaChart, Area, ResponsiveContainer, XAxis } from "recharts";

const data = [
  {
    name: "Today",
    uv: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Year 1",
    uv: 500,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Year 2",
    uv: 1000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Year 3",
    uv: 1500,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Year 4",
    uv: 2000,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Year 5",
    uv: 2500,
    pv: 3800,
    amt: 2500,
  },
 
];

export default function AreaChartComponent() {
  return (
    <ResponsiveContainer width='100%' height={250}>
    <AreaChart
      
      data={data}
      margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: 5,
      }}
    >
      <XAxis dataKey="name" />
      <Area dataKey="uv" stroke="#04A287" fill="#04A287" fillOpacity={1} />
    </AreaChart>
    </ResponsiveContainer>
  );
}
