import React, { useState } from "react";
import { MdAddToPhotos, MdPendingActions } from "react-icons/md";
import { FcClock } from "react-icons/fc";
import {
  BsThreeDotsVertical,
  BsPlusCircleFill,
  BsFillClipboardMinusFill,
} from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiFolderAddFill } from "react-icons/ri";
import SpinLoader from "../../componets/loader/SpinLoader";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

function BuilderAddNFT() {
  let navigate = useNavigate();
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [nftForms, setNftForms] = useState([
    {
      PropertyPrice: 0,
      NumberOfFractions: 0,
      PropertyLocation: "",
      PropertyName: "",
      uri: "",
      coverImage: "",
      description: "",
      Attribute1: "",
      Attribute2: "",
      Attribute3: "",
      PropertyType: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e, callback) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        callback(reader.result);
      };
    }
  };

  const handleNftChange = (index, e) => {
    const { name, value, type } = e.target;
    const newNftForms = [...nftForms];
    if (type === "file") {
      handleFileChange(e, (base64) => {
        newNftForms[index] = { ...newNftForms[index], [name]: base64 };
        setNftForms(newNftForms);
      });
    } else {
      newNftForms[index] = { ...newNftForms[index], [name]: value };
      setNftForms(newNftForms);
    }
  };

  const addNftForm = () => {
    setNftForms([
      ...nftForms,
      {
        PropertyPrice: 0,
        NumberOfFractions: 0,
        PropertyLocation: "",
        PropertyName: "",
        uri: "",
        coverImage: "",
        description: "",
        Attribute1: "",
        Attribute2: "",
        Attribute3: "",
        PropertyType: "",
      },
    ]);
  };

  const removeLastNftForm = () => {
    if (nftForms.length > 1) {
      setNftForms(nftForms.slice(0, -1));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("token");

    const fullData = {
      collectionId: id,
      nftArray: nftForms,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/v0/collection/addMultipleNft`,
        fullData,
        {
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success("NFT added successfully");
        navigate("/builderdashboard");
        setIsLoading(false);
      } else {
        toast.error("Something went wrong!");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error adding Collection and NFT");
      setIsLoading(false);
    }
  };

  return (
    <div className="p-20 relative w-full flex flex-col bg-[#f4f4f4] gap-10">
      <h1 className=" text-3xl text-neutral-800 font-semibold text-center">
        Builder Dashboard
      </h1>

      {isLoading && <SpinLoader />}
      <form onSubmit={handleSubmit}>
        <div className="grid p-20 grid-cols-4 bg-white rounded-xl shadow-md w-full gap-14">
          {nftForms.map((nftForm, index) => (
            <div key={index} className="relative col-span-4 p-4 rounded-lg">
              <h2
                className="text-2xl font-semibold mb-5"
                style={{ color: "#04A287" }}
              >
                Create NFT {index + 1}
              </h2>
              <div className="grid grid-cols-4 gap-12">
                <div className="relative col-span-2">
                  <input
                    name="PropertyName"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`PropertyName-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`PropertyName-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Name
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="PropertyLocation"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`PropertyLocation-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`PropertyLocation-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Location
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="PropertyPrice"
                    onChange={(e) => handleNftChange(index, e)}
                    type="number"
                    id={`PropertyPrice-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`PropertyPrice-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Price
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="NumberOfFractions"
                    onChange={(e) => handleNftChange(index, e)}
                    type="number"
                    id={`NumberOfFractions-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full font-semibold text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`NumberOfFractions-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Number of Fractions
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="coverImage"
                    onChange={(e) => handleNftChange(index, e)}
                    type="file"
                    id={`coverImage-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`coverImage-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Cover Image
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="uri"
                    onChange={(e) => handleNftChange(index, e)}
                    type="file"
                    id={`uri-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`uri-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property File
                  </label>
                </div>

                <div className="relative col-span-4">
                  <textarea
                    name="description"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`description-${index}`}
                    rows={5}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`description-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Description
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="Attribute1"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`Attribute1-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`Attribute1-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Attribute 1
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="Attribute2"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`Attribute2-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`Attribute2-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Attribute 2
                  </label>
                </div>

                <div className="relative col-span-2">
                  <input
                    name="Attribute3"
                    onChange={(e) => handleNftChange(index, e)}
                    type="text"
                    id={`Attribute3-${index}`}
                    className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`Attribute3-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Attribute 3
                  </label>
                </div>

                <div className="relative col-span-2">
                  <select
                    name="PropertyType"
                    onChange={(e) => handleNftChange(index, e)}
                    id={`PropertyType-${index}`}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                  >
                    <option value="">Select Property Type</option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                  <label
                    htmlFor={`PropertyType-${index}`}
                    className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-primary-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Property Type
                  </label>
                </div>
              </div>
            </div>
          ))}
          <div className="col-span-4 flex justify-center">
            <button
              type="button"
              onClick={addNftForm}
              className="flex items-center gap-2"
              style={{ color: "#04A287" }}
            >
              <BsPlusCircleFill className="text-6xl" />
            </button>
            {nftForms.length > 1 ? (
              <button
                type="button"
                onClick={removeLastNftForm}
                className="flex items-center justify-center gap-2 ms-1 p-7 bg-red-500 text-white font-bold rounded-full text-xl h-12 w-12"
              >
                -
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="col-span-4">
            <button
              type="submit"
              className={`w-full text-white bg-[#04A287] py-2 rounded-lg font-semibold ${isLoading ? "disabled:opacity-50 cursor-not-allowed" : ""}`}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Create NFT"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BuilderAddNFT;
