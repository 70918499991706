import React from 'react'

function FilterSection() {
  return (
    <div className=' w-full h-full flex flex-col'>
        <div className='flex justify-between items-center mb-5'>
            <h1 className=' text-2xl font-bold text-primary-1'>Filter</h1>
            <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
        </div>

{/* ----Property Type -------- */}
        <div className=' flex flex-col gap-3'>
            <div className='flex justify-between items-center'>
                <h1 className=' text-base font-semibold text-neutral-800'>Property Type</h1>
                <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
            </div>
            <div className=' flex flex-col gap-3'>
                {/* option 1 All */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>All</label>
                </div>

                {/*  option 2 Commercial */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>Commercial</label>
                </div>
                
                {/*  option 3 Residential */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>Residential</label>
                </div>

                {/*  option 4 Plots */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>Plots</label>
                </div>

            </div>
        </div>

{/* ---- Sales Type -------- */}
        <div className=' flex flex-col gap-3 mt-5'>
            <div className='flex justify-between items-center'>
                <h1 className=' text-base font-semibold text-neutral-800'>Sales Type</h1>
                <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
            </div>
            <div className=' flex flex-wrap gap-3'>
                {/* option 1 All */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="all" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='all' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>All</label>
                </div>

                {/*  option 2 Coming Soon */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="coming" value="cb3"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='coming' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Coming Soon</label>
                </div>
                
                {/*  option 3 Open */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="open" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='open' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Open</label>
                </div>

                {/*  option 4 Resale */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="resale" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='resale' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Resale</label>
                </div>

                {/*  option 5 Sold Out */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="sold" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='sold' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Sold Out</label>
                </div>

            </div>
        </div>


{/* ---- Property Phases -------- */}
        <div className=' flex flex-col gap-3 mt-5'>
            <div className='flex justify-between items-center'>
                <h1 className=' text-base font-semibold text-neutral-800'>Property Phases</h1>
                <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
            </div>
            <div className=' flex flex-wrap gap-3'>
                {/* option 1 Public Sale */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="public" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='public' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Public Sale</label>
                </div>

                {/*  option 2 Presale */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="presale" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='presale' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Presale</label>
                </div>
                
            </div>
        </div>


{/* ---- City -------- */}
        <div className=' flex flex-col gap-3 mt-5'>
            <div className='flex justify-between items-center'>
                <h1 className=' text-base font-semibold text-neutral-800'>City</h1>
                <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
            </div>
            <div className=' flex flex-wrap gap-3'>
                {/* option 1 Coimbatore */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="coimbatore" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='coimbatore' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Coimbatore</label>
                </div>
            </div>
        </div>


{/* ---- Builders Name -------- */}
        <div className=' flex flex-col gap-3 mt-5'>
            <div className='flex justify-between items-center'>
                <h1 className=' text-base font-semibold text-neutral-800'>Builders Name</h1>
                <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
            </div>
            <div className=' flex flex-wrap gap-3'>
                {/* option 1 Vascon */}
                <div class='flex flex-col w-fit justify-between border border-slate-300 rounded-md '>
                    <input type="checkbox" id="vascon" value="cb4"
                    class='
                        appearance-none h-fit w-full bg-white 
                        checked:bg-primary-1-light 
                        transition-all duration-300 peer'/>
                    <label for='vascon' class='px-6 py-1 flex rounded-md flex-row justify-center peer-checked:text-white peer-checked:bg-primary-1 select-none text-neutral-600'>Vascon</label>
                </div>
            </div>
        </div>

{/* ----Price Range -------- */}
<div className=' flex flex-col gap-3 my-5'>
            <div className='flex justify-between items-center'>
                <h1 className=' text-base font-semibold text-neutral-800'>Price Range</h1>
                <button className=' text-xs px-2 py-1 border text-neutral-500 border-slate-300 font-semibold rounded-md'>Clear</button>
            </div>
            <div className=' flex flex-col gap-3'>
                {/* option 1 Upto 1500 */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>Upto 1500</label>
                </div>

                {/*  option 2 1500-3000 */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>1500-3000</label>
                </div>
                
                {/*  option 3 3000-7000 */}
                <div class='flex flex-row'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>3000-7000</label>
                </div>

                {/*  option 4 Above 7000 */}
                <div class='flex flex-row mb-5'>
                    <input type="checkbox" id="cb1" value="cb1"
                    class='
                        appearance-none h-6 w-6 bg-gray-300 rounded-md 
                        checked:bg-green-300 checked:scale-75
                        transition-all duration-200 peer'
                            />
                    <div class='h-6 w-6 absolute rounded-md pointer-events-none peer-checked:border-green-300 peer-checked:border-2'>
                    </div>
                    <label for='cb1' class='flex flex-col justify-center px-2 peer-checked:text-green-400  select-none'>Above 7000</label>
                </div>

            </div>
        </div>

    </div>
  )
}

export default FilterSection