import "./landingPage.css";
import { Button, Flex, Modal } from "antd";
import { useEffect, useState } from "react";
import { WalletOutlined } from "@ant-design/icons";
import { SiCakephp } from "react-icons/si";
import { FaGlobe } from "react-icons/fa6";
import { useTheme } from "../../hooks/usetheme";
import axios from "axios";
import toast from "react-hot-toast";
import Staking2 from "../../componets/stakeFarm/staking2";
import Staking1 from "../../componets/stakeFarm/staking1";
import MyPosition from "../../componets/stakeFarm/myPosition";

// Custom toast component
const CustomToast = ({ transactionHash }) => (
  <div
    style={{
      padding: "10px",
      backgroundColor: "#1a202c",
      color: "#fff",
      borderRadius: "5px",
      fontSize: "16px",
    }}
  >
    Transaction Successful!{" "}
    <a
      href={`https://amoy.polygonscan.com/tx/${transactionHash}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: "#63b3ed",
        textDecoration: "underline",
        fontWeight: "bold",
      }}
    >
      View Transaction
    </a>
  </div>
);

const NavItems = ["Staking 2.0", "Staking 1.0", "My Position"];

const LandingPage = () => {
  const { theme } = useTheme();
  const [activeNav, setActiveNav] = useState(0);
  const [data, setData] = useState([
    { reward: "2%", periodTime: "30 days" },
    { reward: "5%", periodTime: "60 days" },
    { reward: "12%", periodTime: "90 days" },
    { reward: "18%", periodTime: "180 days" },
    { reward: "24%", periodTime: "365 days" },
  ]);
  const [data1, setData1] = useState([
    { reward: "1%", periodTime: "30 days" },
    { reward: "3%", periodTime: "60 days" },
    { reward: "10%", periodTime: "90 days" },
    { reward: "15%", periodTime: "180 days" },
    { reward: "22%", periodTime: "365 days" },
  ]);
  const [inputValues, setInputValues] = useState(Array(data.length).fill(""));
  const [calculatedRewards, setCalculatedRewards] = useState(
    Array(data.length).fill(0)
  );
  const [maturityDates, setMaturityDates] = useState(
    Array(data.length).fill("")
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    reward: "",
    periodTime: "",
    amount: "",
    maturityDate: "",
  });
  const [selectedIndex, setSelectedIndex] = useState(null); // Track selected index for staking
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [activeCard, setActiveCard] = useState(null);
  const [totalOG, setTotalOG] = useState(0);
  const [stakeType, setStakeType] = useState("");
  const [stakeTransaction, setStakeTransaction] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const fetchStakeTransaction = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${baseUrl}/v0/my-stake`, {
        headers: {
          token: token,
        },
      });

      setStakeTransaction(response.data.responseData);
    } catch (error) {
      console.error("There was an error making the request:", error);
    }
  };
  useEffect(() => {
    fetchStakeTransaction();
    const fetchTotalOG = async () => {
      try {
        const response = await axios.get(`${baseUrl}/v0/total-og-stake`);

        setTotalOG(response.data.responseData);
      } catch (error) {
        console.error("There was an error making the request:", error);
      }
    };
    fetchTotalOG();
  }, []);

  const toggleActionButtons = (index) => {
    setActiveCard(activeCard === index ? null : index);
  };
  const formatDuration = (duration) => {
    return duration.replace(/(\d+)(\w+)/, "$1 $2");
  };
  const checkMaturity = (duration) => {
    const currentDate = new Date();
    const daysToAdd = parseInt(duration.replace("days", "").trim(), 10);
    const maturityDate = new Date(currentDate);
    maturityDate.setDate(currentDate.getDate() + daysToAdd);

    return currentDate < maturityDate;
  };

  const calculateReward = (stakeAmount, duration) => {
    // Add a space between the number and the unit for matching
    const formattedDuration = duration.replace(/(\d+)(\w+)/, "$1 $2");

    // Find the matching periodTime in the data array
    const rewardData = data.find(
      (item) => item.periodTime === formattedDuration
    );

    if (rewardData) {
      const rewardPercentage = parseFloat(rewardData.reward);
      const rewardAmount = (stakeAmount * rewardPercentage) / 100;
      return rewardAmount;
    } else {
      return 0;
    }
  };
  function addDuration(createdAt, duration) {
    const durationValue = parseInt(duration, 10);
    const createdDate = new Date(createdAt);
    const maturityDate = new Date(createdDate);
    maturityDate.setDate(createdDate.getDate() + durationValue);
    return maturityDate.toISOString().split("T")[0]; // Only return the date part
  }
  const handleInputChange = (value, index) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    const stakeAmount = parseFloat(value);
    const rewardPercentage = parseFloat(data[index].reward) / 100;
    const reward = stakeAmount * rewardPercentage;
    const newCalculatedRewards = [...calculatedRewards];
    newCalculatedRewards[index] = reward;
    setCalculatedRewards(newCalculatedRewards);

    const periodDays = parseInt(data[index].periodTime.split(" ")[0]);
    const maturityDate = new Date();
    maturityDate.setDate(maturityDate.getDate() + periodDays);
    const newMaturityDates = [...maturityDates];
    newMaturityDates[index] = maturityDate.toDateString();
    setMaturityDates(newMaturityDates);
  };
  const unstake = async (duration) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/v0/user/unstake`,
        { duration }, // Ensure the payload is sent correctly
        {
          headers: {
            token: token,
          },
        }
      );

      if (response.data.success) {
        toast.success("Unstake Successful!");
        fetchStakeTransaction();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error("There was an error making the request:", error);
    }
  };
  const handleCard = (index) => {
    setData((prevState) =>
      prevState.map((data, i) =>
        i === index ? { ...data, bool: !data.bool } : { ...data, bool: false }
      )
    );
  };

  const handleModal = (index) => {
    setSelectedIndex(index); // Set the selected index for staking
    setData((prevState) =>
      prevState.map((stateData, i) =>
        i === index ? { ...stateData, modelOpen: true } : stateData
      )
    );
  };

  const handleSubmit = async () => {
    if (selectedIndex === null) return; // Ensure index is set

    const stakeAmount = parseFloat(inputValues[selectedIndex]);
    let duration = modalContent.duration.trim();
    duration = duration.replace(/\s+/g, "");
    const requestData = {
      stakeAmount: stakeAmount,
      duration: duration,
      stakeType: stakeType,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/v0/user/stake`,
        requestData,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.message === "Successful") {
        const transactionHash = response.data.responseData.transactionHash; // Assuming transactionHash is in the response
        toast.custom(<CustomToast transactionHash={transactionHash} />);
        fetchStakeTransaction();
      } else {
        toast.error("Failed to Stake!");
      }
    } catch (error) {
      toast.error("Failed to Stake!");
      console.error("Error staking:", error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const confirmButtonStyle = {
    backgroundColor: "#04A287",
    color: "#ffffff",
  };

  const handleCardClick = (e, index) => {
    e.stopPropagation();
    handleCard(index);
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const showModal = (index, type) => {
    const amount = inputValues[index];
    const reward = calculatedRewards[index].toFixed(2);
    const maturityDate = maturityDates[index];
    const duration = data[index].periodTime; // Get the duration from the data
    setModalContent({
      reward: data[index].reward,
      periodTime: data[index].periodTime,
      amount,
      maturityDate,
      duration, // Add duration to modal content
    });
    setIsModalVisible(true);
    setSelectedIndex(index); // Set the selected index for modal
    setStakeType(type);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsLoading(true); // Set loading state
    handleSubmit(); // Call the submit function when confirming
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div
        className={`centerBox ${
          theme === "dark" ? "bg-gray-700" : "bg-[#9CDBA6]"
        } z-0 relative flex flex-col lg:flex-row gap-5 p-8 w-full`}
      >
        {/* ----------- right side box ------------------- */}
        <div className={`center-right-box flex flex-col gap-5 w-full  lg:w-1/2`}>
          <div
            className={`Center-right-nav-Container ${
              theme === "dark" ? "bg-gray-900" : "bg-[#D6EFD8]"
            } rounded-lg shadow-lg p-5 md:p-10`}
          >
            <ul className="Center-right-nav-list flex justify-between">
              {NavItems.map((data, index) => (
                <li
                  className={`Center-right-nav-list-item ${
                    activeNav === index ? "active-nav" : ""
                  } ${
                    theme === "light" ? "text-black" : "text-white"
                  } cursor-pointer pb-1 text-sm md:text-lg font-semibold`}
                  key={index}
                  onClick={() => setActiveNav(index)}
                >
                  {data}
                </li>
              ))}
            </ul>
          </div>
          <Staking2
            activeNav={activeNav}
            data={data}
            handleInputChange={handleInputChange}
            handleCardClick={handleCardClick}
            showModal={showModal}
            theme={theme}
            calculatedRewards={calculatedRewards}
            maturityDates={maturityDates}
            inputValues={inputValues}
            handleInputClick={handleInputClick}
            stakeTransaction={stakeTransaction}
          />
          <Staking1
            activeNav={activeNav}
            data={data1}
            handleInputChange={handleInputChange}
            handleCardClick={handleCardClick}
            showModal={showModal}
            theme={theme}
            calculatedRewards={calculatedRewards}
            maturityDates={maturityDates}
            inputValues={inputValues}
            handleInputClick={handleInputClick}
            stakeTransaction={stakeTransaction}
          />
          <MyPosition
            activeNav={activeNav}
            stakeTransaction={stakeTransaction}
            theme={theme}
            addDuration={addDuration}
            calculateReward={calculateReward}
            formatDuration={formatDuration}
            checkMaturity={checkMaturity}
            unstake={unstake}
            refresh={fetchStakeTransaction}
          />
        </div>
        {/* ------- left side box ----------- */}
        <div
          className={`center-left-box ${
            theme === "dark" ? "bg-gray-900" : "bg-[#D6EFD8]"
          } w-full lg:w-1/2 rounded-xl shadow-lg px-10 pt-5 pb-10 sticky top-5 self-start`}
        >
          <div
            className={`center-left-top-box ${
              theme === "dark" ? "bg-gray-900" : "bg-white-900"
            } flex items-center justify-between`}
          >
            <div>
              <p
                className={`text-base ${
                  theme === "light" ? "text-black" : "text-white"
                } text-lg font-semibold`}
              >
                Total OG Staked
              </p>
              <h1
                className={`md:text-4xl ${
                  theme === "light" ? "text-black" : "text-white"
                } font-semibold text-neutral-800`}
              >
                {totalOG} OG
              </h1>
            </div>
            <div
              className={`${
                theme === "dark"
                  ? "bg-white w-80 h-80 border-r-8 "
                  : "center-left-top-img-box"
              }`}
            >
              <img src="/images/stake22.png" alt="" className="w-80 h-80" />
            </div>
          </div>
          <div
            className={`center-left-btm-box w-full md:w-2/3 ${
              theme === "dark" ? "bg-gray-900" : "bg-white-900"
            }`}
          >
            <h3
              className={`text-3xl mb-6 ${
                theme === "light" ? "text-black" : "text-white"
              } font-semibold`}
            >
              Stake and farm your og token to earn rewards .
            </h3>
            <p
              className={`text-xl mb-6 ${
                theme === "light" ? "text-black" : "text-white"
              } text-neutral-700 font-semibold`}
            >
              Get exclusive access to top-tier launches and earn high APR
            </p>
            <p
              className={`text-xl mb-4 ${
                theme === "light" ? "text-black" : "text-white"
              }`}
            >
              Supported Exchanges To Buy OG
            </p>
            <div className=" flex gap-8 flex-col md:flex-row " >
              <button
                className={`rounded-full ${
                  theme === "light" ? "text-black" : "text-white"
                } flex items-center w-fit text-lg transition-all text-[#f19020] border border-[#f19020] hover:bg-[#f19020] hover:text-white p-2 gap-1`}
              >
                <SiCakephp /> Pancake Swap
              </button>
              <button
                className={`rounded-full ${
                  theme === "light" ? "text-black" : "text-white"
                } flex items-center w-fit text-lg transition-all text-primary-1 border border-[rgb(4,162,135)] hover:bg-[rgb(4,162,135)] hover:text-white p-2 gap-1`}
              >
                <FaGlobe /> MEXC Global
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for staking confirmation */}
      <Modal
        title="Confirm Your Staking"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={
          isLoading ? (
            <span>
              <span className="loader"></span> Processing...
            </span>
          ) : (
            "Confirm"
          )
        }
        cancelButtonProps={{
          disabled: isLoading, // Disable cancel button while loading
        }}
        okButtonProps={{
          disabled: isLoading, // Disable confirm button while loading
          style: isLoading
            ? { opacity: 0.5, cursor: "not-allowed" }
            : {
                backgroundColor: confirmButtonStyle.backgroundColor, // Ensure background color is consistent
                color: confirmButtonStyle.color,
              },
        }}
        bodyStyle={{ padding: "24px" }}
        width={600}
      >
        <p>
          Are you sure you want to stake{" "}
          <strong>{modalContent.amount} OG</strong>? This will yield a reward of{" "}
          <strong>{modalContent.reward}</strong> with a maturity date of{" "}
          <strong>{modalContent.maturityDate}</strong>.
        </p>
      </Modal>
    </div>
  );
};

export default LandingPage;
