import { HeroSection } from "./heroSection";
import Carousel from "./carousel";
import RealWord from "./RealWorld";
import Tokenization from "./Tokenisation";
import KeyFeatures from "./KeyFeatures";
import How from "./WorkCard";
import Testimonials from "./Testimonials";
import Partners from "./Partners";
import Blogs from "./Blogs";
import GlobeUi from "./globe";


import "./home.css";
import FaqComponent from "../faqPage/FaqComponent";
export const HomePage = () => {
  return (
    <div className="bg-white overflow-x-hidden">
      <HeroSection />
      {/* <GlobeUi /> */}
      <RealWord />
      <Tokenization />
      <How />
      <KeyFeatures />
      <Carousel />
      <br />
      <br />
      <Testimonials />
     
      <Blogs />
      <FaqComponent />
    </div>
  );
};
