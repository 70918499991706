import React, { useState } from 'react'
import faqItems from './faqItems'
import { FcFaq } from 'react-icons/fc'

function FaqComponent() {

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = faqItems.slice(firstIndex, lastIndex);
    const npage = Math.ceil(faqItems.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function nextPage() {
        if(currentPage !== npage){
            setCurrentPage(currentPage + 1);
        }

    }

    function prevPage() {
        if(currentPage !== 1){
            setCurrentPage(currentPage - 1);
        }

    }

    function changeCPage(id) {
        setCurrentPage(id);
    }



  return (
    <div className=' w-full p-10 flex flex-col-reverse xl:flex-row '>

        <div className=' w-full xl:w-1/2 flex flex-col shadow-lg rounded-md'>
            <img src='/images/faq.png' className=' w-full' />
            <div className=' w-full px-5 flex flex-col gap-3 items-center -translate-y-20'>
                <h1 className='text-primary-1 text-2xl font-bold text-wrap text-center'>Didn't find an answer to your question ?</h1>
                <span className=' text-neutral-500 text-lg font-semibold text-wrap text-center'>Get in touch with us for details on additional services.</span>
                <button className=' px-5 py-2 font-semibold mt-3 rounded-md text-white bg-primary-1 hover:scale-95 shadow-md'>Contact Us</button>

            </div>
        </div>

        <div className=' w-full xl:w-1/2 flex flex-col gap-10 p-5'>
            <h1 className=' flex gap-2 w-full justify-center text-3xl text-center text-neutral-800 font-bold'><FcFaq /> FAQ</h1>

            <div className=' flex flex-col gap-5'>
                {records.map((d,i) => (
                    <div className="py-5 w-full shadow-xl rounded-2xl">
                    <details className="group">
                      <summary className="flex justify-between items-center font-medium px-5 cursor-pointer list-none">
                        <span className=' text-heading self-center text-lg pt-2 font-semibold'> {d.question} </span>
                        <span className="transition group-open:rotate-180">
                          <svg
                            fill="none"
                            height="24"
                            shape-rendering="geometricPrecision"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </summary>
                      <div className="text-neutral-600 mt-3 px-3 py-1 group-open:animate-fadeIn">
                      {d.answer}
                      </div>
                    </details>
                  </div>
                ))}
            </div>

            <div className='flex w-fit self-center shadow-lg rounded-md'>
                <span onClick={prevPage} className='px-4 py-2 hover:bg-primary-1-light hover:text-white cursor-pointer text-primary-1 rounded-md'>Prev</span>
                {
                    numbers.map((n, i) => (
                        <span key={i} className={`px-4 py-2  cursor-pointer rounded-md shadow-md border-neutral-600 ${currentPage === n?'bg-primary-1 text-white ' : ' bg-white text-primary-1 hover:bg-primary-1-light hover:text-white'}`} onClick={() => changeCPage(n)}>
                            {n}
                        </span>
                    ))
                }
                <span onClick={nextPage} className='px-4 py-2 hover:bg-primary-1-light hover:text-white cursor-pointer text-primary-1 rounded-md border-neutral-600'>Next</span>
            </div>
        
        </div>
        
    </div>
  )
}

export default FaqComponent