import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

function MyPosition({
  activeNav,
  stakeTransaction,
  theme,
  addDuration,
  calculateReward,
  formatDuration,
  checkMaturity,
  unstake,
  refresh
}) {
  const [activeCard, setActiveCard] = useState(null); // State to manage active card
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleCardClick = (index) => {
    setActiveCard((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleEmergencyUnstake = async (duration, stakeType) => {
    try {
      const data = { duration, stakeType };
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("No token found. Please log in.");
        return;
      }

      const response = await axios.post(
        `${baseUrl}/v0/user/emergency-unstake`,
        data,
        {
          headers: {
            accept: "application/json",
            token: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        toast.success("Unstake Successful!");
        refresh();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error("Emergency unstake failed", error);
      toast.error("Emergency unstake failed. Please try again.");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No data available";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      {activeNav === 2 &&
        stakeTransaction.map((dataItem, index) => (
          <div
            className={`Stake-Card ${
              theme === "dark" ? "bg-gray-900" : "bg-[#D6EFD8]"
            } px-3 md:px-10 py-5 rounded-lg shadow-lg`}
            onClick={() => handleCardClick(index)}
            key={index}
          >
            <div
              className={`Stake-Main-Box ${
                theme === "dark" ? "bg-gray-900" : "bg-white-900"
              } flex justify-between w-full gap-10`}
            >
              {/* Left Box */}
              <div className="Stake-Card-Left-Box w-1/4">
                <div className="Stake-Card-Left-Img-Box">
                  <img src="" alt="" />
                </div>
                <div
                  className="Stake-Card-Left-Content-Box ribbon-container"
                  data-label={
                    dataItem.stakingType === "stake2"
                      ? "Stake 2.0"
                      : "Stake 1.0"
                  }
                >
                  <p
                    className={`mb-5 md:text-base text-sm  font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Staked Amount
                  </p>
                  <p className="md:text-lg text-sm text-neutral-700 font-semibold">
                    {dataItem.totalStakedAmount} OG
                  </p>
                </div>
              </div>
              {/* Right Box */}
              <div className="Stake-Card-Right-Box flex w-3/4 justify-between">
                <div>
                  <p
                    className={`mb-5 md:text-base text-sm  font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Maturity Date
                  </p>
                  <p className="md:text-xl text-base text-[#576ddb]">
                    {formatDate(dataItem.maturityDate)}
                  </p>
                </div>
                <div>
                  <p
                    className={`mb-5 md:text-base text-sm  font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Reward Earn
                  </p>
                  <p className="md:text-xl text-base text-primary-1">
                    {calculateReward(
                      dataItem.totalStakedAmount,
                      dataItem.duration
                    )}
                  </p>
                </div>
                <div>
                  <p
                    className={`mb-5 md:text-base text-sm  font-bold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    Period
                  </p>
                  <p className="md:text-xl text-base text-[#f19020]">
                    {formatDuration(dataItem.duration)}
                  </p>
                </div>
              </div>
            </div>
            {activeCard === index && ( // Show additional content if this card is active
              <div className="text-center">
                <div className="mt-2">
                  <button
                    className={`mr-2 p-2 w-60 rounded shadow shadow-slate-300 ${
                      checkMaturity(dataItem.duration)
                        ? "bg-gray-400 text-gray-700"
                        : "bg-white text-[#31374D]"
                    }`}
                    disabled={checkMaturity(dataItem.duration)}
                    onClick={() => unstake(dataItem.duration)}
                  >
                    <span className="inline-block align-middle">
                      <img
                        src="/images/unstake.png"
                        alt="Unstake Icon"
                        className="w-4 h-4 mr-2 inline-block align-middle"
                      />
                    </span>
                    <span className="inline-block align-middle">Unstake</span>
                  </button>
                  <button
                    className="mr-2 p-2 w-60 bg-white text-[#31374D] rounded shadow shadow-slate-300"
                    onClick={() =>
                      handleEmergencyUnstake(
                        dataItem.duration,
                        dataItem.stakingType
                      )
                    }
                  >
                    <span className="inline-block align-middle">
                      <img
                        src="/images/EmergencyUnstake.png"
                        alt="Emergency Unstake Icon"
                        className="w-4 h-4 mr-2 inline-block align-middle"
                      />
                    </span>
                    <span className="inline-block align-middle">
                      Emergency Unstake
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
    </>
  );
}

export default MyPosition;
