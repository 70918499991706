import React, { useState } from 'react'
import prop1 from "../../../assets/images/property/prop1.jpeg";
import { FaEdit } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

function EditNft() {

    const {collectionId, nftId} = useParams();


    const [NftFormData, setNftFormData] = useState({
        coverImage: '',
        uri: '',
        description: " a premier residential community that epitomizes luxury and modern living in the heart of the city. ",
        PropertyName: 'Flat A-101',
        PropertyLocation: 'Pune',
        Attribute1: "2 BHK",
        Attribute2: "Full Furnished",
        Attribute3: "1000 sqft",
        PropertyType: "Tenant",
    });

    const [imagePreviews, setImagePreviews] = useState({
        coverImagePreview: prop1,
        uriPreview: prop1,
    });


    const handleFileChange = (event, callback) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                callback(reader.result, file);
            };
        }
    };

    const handleNftChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "file") {
            handleFileChange(e, (base64, file) => {
                setNftFormData((prevData) => ({
                    ...prevData,
                    [name]: base64
                }));
                setImagePreviews((prevPreviews) => ({
                    ...prevPreviews,
                    [`${name}Preview`]: URL.createObjectURL(file)
                }));
            });
        } else {
            setNftFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
    };

  return (
    <div className=' m-20 p-10 bg-white rounded-md flex flex-col'>
        <h1 className=' text-3xl px-8 text-primary-1 font-semibold'>Edit NFT</h1>
        <form onSubmit={handleSubmit} className=' w-full'>
                <div className='grid p-8 grid-cols-4 bg-white rounded-xl  w-full gap-10'>
                 

                    {/* nft Name */}
                    <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Property Name</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="PropertyName"
                        id="PropertyName"
                        value={NftFormData.PropertyName}
                        onChange={handleNftChange}
                        />
                    </div>

                    {/* location */}
                    <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Property Location</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="PropertyLocation"
                        id="PropertyLocation"
                        value={NftFormData.PropertyLocation}
                        onChange={handleNftChange}
                        />
                    </div>

                               

                    {/* Collection Image */}
            <div className='col-span-2 flex flex-col gap-1'>
                <h1 className='text-sm font-semibold text-heading ml-3'>Cover Image</h1>
                <div className=' flex gap-8'>
                <input
                    className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                    type="file"
                    name="coverImage"
                    id="coverImage"
                    onChange={handleNftChange}
                />
                {imagePreviews.coverImagePreview && (
                    <img
                        src={imagePreviews.coverImagePreview}
                        alt="Cover Preview"
                        className="mt-2 w-32 h-32 object-cover rounded-lg"
                    />
                )}
                </div>
            </div>

            {/* Banner Image */}
            <div className='col-span-2 flex flex-col gap-1'>
                <h1 className='text-sm font-semibold text-heading ml-3'>Uri</h1>
                <div className=' flex gap-8'>
                <input
                    className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                    type="file"
                    name="uri"
                    id="uri"
                    onChange={handleNftChange}
                />
                {imagePreviews.uriPreview && (
                    <img
                        src={imagePreviews.uriPreview}
                        alt="uri Preview"
                        className="mt-2 w-32 h-32 object-cover rounded-lg"
                    />
                )}
                </div>
            </div>
                  
                
                 {/* Attribute 1 */}
                 <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Attribute 1</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="Attribute1"
                        id="Attribute1"
                        value={NftFormData.Attribute1}
                        onChange={handleNftChange}
                        />
                    </div>
                     
                
                 {/* Attribute 2 */}
                 <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Attribute 2</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="Attribute2"
                        id="Attribute2"
                        value={NftFormData.Attribute2}
                        onChange={handleNftChange}
                        />
                    </div>
                     
                
                 {/* Attribute 3 */}
                 <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Attribute 3</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="Attribute3"
                        id="Attribute3"
                        value={NftFormData.Attribute3}
                        onChange={handleNftChange}
                        />
                    </div>


                     
                    <div className="col-span-2 flex flex-col gap-1">
                    <h1 className='text-sm font-semibold text-heading ml-3'>Property Type</h1>
                    <select
                        name="PropertyType"
                        onChange={handleNftChange}
                        id="PropertyType"
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                    >
                        <option value="" disabled selected></option>
                        <option
                        className=" text-base option-styling"
                        value="Tenant"
                        >
                        Tenant
                        </option>
                        <option
                        className="text-base option-styling"
                        value="Pre-build"
                        >
                        Pre-build
                        </option>
                    </select>
                 
                </div>
                    

                   

                    {/* Description */}
                    <div className='col-span-4 flex flex-col'>
                        <h1 className='text-sm font-semibold text-heading'>Description</h1>
                        <textarea
                        className="mt-1 pl-5 py-2 border border-neutral-400 focus:outline-none focus:border-emerald-400  text-heading rounded-md shadow-sm"
                        name="description"  
                        id="description"     
                        value={NftFormData.description}         
                        onChange={handleNftChange}            
                        rows={5}            
                        />
                    </div>


                   
                 
            <div className=' col-span-4 flex  justify-end gap-5'>
          <button className=' px-8 py-1 rounded-md font-semibold hover:bg-neutral-600 text-white bg-neutral-700'>
            Cancel
          </button>
          <button type="submit" className=' px-10 bg-primary-1 hover:scale-95 transition-all text-white py-2 rounded-md font-semibold'>Submit</button> 
            </div>
          </div>

          </form>
    </div>
  )
}

export default EditNft