import React from 'react'
import { BsDashLg } from 'react-icons/bs'
import { CiClock2 } from "react-icons/ci";
import { FaFacebook, FaPinterest } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward, IoLogoTwitter } from 'react-icons/io';
import { MdBarChart } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { blogData } from './blogData';
import { HiMiniArrowLongRight } from 'react-icons/hi2';


const blog1 = `${process.env.PUBLIC_URL}/images/blog1.png`
const blog2 = `${process.env.PUBLIC_URL}/images/blog2.png`
const blog3 = `${process.env.PUBLIC_URL}/images/blog3.png`

const blog = [
    {
        id: 1,
        img: blog1,
        title: 'The real estate industry, a cornerstone of global economies',
        desc:'It is undergoing a significant transformation with the advent of blockchain technology.',
        time: '2 min read',
        views: '1209 views',
        date: '01 aug 2024',
    },
    {
        id: 2,
        img: blog2,
        title: 'High-value collectibles and vintage assets',
        desc:'the intersection of blockchain technology and real-world asset (RWA) tokenization',
        time: '5 min read',
        views: '1580 views',
        date: '22 july 2024',
    },
    {
        id: 3,
        img: blog3,
        title: 'Financial landscape Undergoing a Profound Transformation',
        desc:'driven by the advent of blockchain technology and the innovative practice of',
        time: '3 min read',
        views: '812 views',
        date: '25 july 2024',
    },
]


const Card = ({data}) => {
    return(
        <div className=' w-[357px] h-[420px] flex flex-col justify-between rounded-xl bg-white shadow-lg'>

             <img src={data.img} alt='blogs' className=' w-[357px] h-[240px] rounded-lg ' />

             <div className=' px-5 pt-2 flex w-full justify-between'>
                <span className=' pr-3 text-xs font-semibold border-r border-black'>{data.time}</span>
                <span className=' pr-8 text-xs font-semibold border-r border-black'>{data.views}</span>
                <span className=' pr-3 text-xs font-semibold'>{data.date}</span>
             </div>

            <div className=' pt-5 px-5'>
                <p className=' text-base text-neutral-700 font-bold'>{data.title}</p>
                <p className=' text-sm mt-3 text-neutral-700 font-semibold'>{data.desc}</p>
            </div>
            <Link  to={`/blogs/${data.id}`}><h1 className='flex gap-3 items-end pb-3 ml-5 mt-2 text-primary-1 font-semibold text-base'>Read Blog <span className=' hover:translate-x-1 transition-all text-xl'><HiMiniArrowLongRight /></span></h1></Link>

        </div>
    )
}

function BlogsDetail() {

    const { id } = useParams();
  const blogId = parseInt(id, 10); // Convert id to an integer
  const blogItem = blogData.find(b => b.id === blogId);

  return (
    <div className=' w-full bg-white flex flex-col  justify-center p-20'>
        <h1 className=' text-4xl sm:px-20 md:px-30 lg:px-60 text-neutral-800 font-semibold text-wrap text-center'>{blogItem.title}</h1>
        <p className=' w-full justify-center gap-3 text-sm font-semibold text-neutral-700 text-center my-10 flex items-center'>by Joanna Wellick <BsDashLg /> <CiClock2 /> 2 minute read <BsDashLg /> <MdBarChart /> 1.6k views <BsDashLg /> <FaFacebook /> <IoLogoTwitter /> <FaPinterest /> 1.2k shares </p>
        <img src={blogItem.img} alt='blog' className=' w-[1286px] h-[610px]' />
        <p className=' text-wrap text-lg text-neutral-700 font-semibold my-10'>{blogItem.content1}</p>
        <h1 className=' text-3xl font-bold text-neutral-800'>Understanding RWA Tokenization</h1>
        <p className=' text-wrap text-lg text-neutral-700 font-semibold my-10'>{blogItem?.content2}</p>
        <h1 className=' text-2xl font-bold text-neutral-800'>Key Benefits of {blogItem?.subtitle1}</h1>
        {blogItem.keybenefits.map((d,i) => (
            <p className='text-wrap text-lg text-neutral-700 font-semibold mt-10 '><span className=' font-bold text-neutral-900'>{d.idx}. {d.title}: </span> {d.desc}</p>
        ))}

        <h1 className=' text-2xl font-bold text-neutral-800 mt-10'>Real-World Applications of  {blogItem?.subtitle2}</h1>
        {blogItem.applications.map((d,i) => (
            <div>
            <p className=' text-lg text-neutral-900 font-bold mt-10 '>{d.idx}. {d.title}:</p>
            <p className='text-wrap text-lg text-neutral-700 font-semibold mt-10 '>{d.desc}</p>
            </div>
        ))}

<h1 className=' text-2xl font-bold text-neutral-800 mt-10'>Challenges and Future Outlook</h1>
<p className=' text-wrap text-lg text-neutral-700 font-semibold mt-10'>{blogItem?.challenge1}</p>
<p className=' text-wrap text-lg text-neutral-700 font-semibold mt-10'>{blogItem?.challenge2}</p>
<h1 className=' text-2xl font-bold text-neutral-800 mt-10'>Conclusion</h1>
<p className=' text-wrap text-lg text-neutral-700 font-semibold mt-10'>{blogItem?.conclusion}</p>

        <div className=' flex w-full justify-center gap-20 my-10'>
            <div className='flex items-center cursor-pointer text-primary-1 gap-2 text-base font-semibold'><IoIosArrowBack /> Previous Article</div>
            <div className='flex items-center cursor-pointer text-primary-1 gap-2 text-base font-semibold'>Next Article <IoIosArrowForward /></div>
            
        </div>
        <div className=' bg-primary-1 rounded-lg w-full shadow-md py-3 tracking-widest text-center text-white font-semibold'>VIEW COMMENTS (0)</div>




        <div className=' flex flex-col gap-6 my-20'>
            <div className=' flex justify-between items-end'>
                <h1 className=' text-3xl text-neutral-700 font-bold'>You May Also Like</h1>
                <Link to='/blogs'> <button className=' mt-10 px-6 py-2 bg-primary-1 text-white font-semibold rounded-md self-end'> Explore More</button> </Link>
            </div>

            <div className=' mt-10 flex flex-wrap w-full gap-10 justify-center'>
                    {blog.map((d,i) => (
                        <Card data={d} />
                    ))}
                </div>
        </div>
    </div>
  )
}

export default BlogsDetail