import React from "react";
import Web3 from "web3";
import { useState } from "react";
import { Button, Flex } from "antd";
import property from "../../src/property.json";
import { WalletOutlined } from "@ant-design/icons";
import { useTheme } from "../hooks/usetheme";
import { Alert } from "antd";
import { Link } from "react-router-dom";

const Cards = ({ 
  data,
  index,
  cardIndex,
  flip,
  setShowIndex,
  indexValue,
  handleloader,
}) => {
  console.log(data, "Data 9");
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setInputValue(""); // Reset input value on close
  };
  const handleInputChange = (e) => setInputValue(e.target.value);
  const handleButtonClick = async (address, value) => {
    handleloader(true);
    try {
      const accounts = await web3.eth.getAccounts();
      
      console.log("vfg",value, address, inputValue, value);
      await contract.methods
        .BUY(value, address, inputValue, value)
        .send({ from: accounts[0] })
        .then((receipt) => {
          handleloader(false);
          setIsOpen(false);
          return <Alert message={`Transaction successful`} type="success" />;
        })
        .catch((error) => {
          handleloader(false);
          console.log(error);
          alert("Transaction failed:", error);
        });
    } catch (error) {
      closeModal();
      handleloader(false);
    }
  };
  const web3 = new Web3(window.ethereum);
  const handleCard = (x) => {
    setInfo((prevState) =>
      prevState?.map((data, index) =>
        index === x ? { ...data, bool: !data?.bool } : { ...data, bool: false }
      )
    );
  };
  const [info, setInfo] = useState([
    {
      address: "0x5e4600f2111f70f4Dc0A01E5E5ef94a4e5590A0b",
      bool: false,
    },
    {
      address: "0x98fC9DF2BD702CACEf710c81275E0D02860b91C1",
      bool: false,
    },
    {
      address: "0x1Ef48Eee44fc96becedee7Cc4Ecc7406Cec1785f",
      bool: false,
    },
    {
      address: "0x12f550B7d27fCA6155f752D1E7f9CFA6D1845950",
      bool: false,
    },
    {
      address: "0x68836b4D0dB2F92ba145175CF36BAA4A93a1991e",
      bool: false,
    },
    {
      address: "0x05E9759FCd6923f59aD71A5232947aDd3476d6d9",
      bool: false,
    },
    {
      address: "0xF781a0591519DDd6E6590Afac7eB2D5331f525B4",
      bool: false,
    },
    {
      address: "0x2D806f660655ea2efc13783d162DCB836261E1D4",
      bool: false,
    },
  ]);
  const contractabi = property.abi;
  const contract = new web3.eth.Contract(
    contractabi,
    "0x695a748Eac9F741a4B4c477961231211B5acfeF7"
  );
  const handleClick = async (params, x, y) => {
    try {
      const accounts = await web3.eth.getAccounts();
      contract.methods
        .BUY(1, params, x, y)
        .send({ from: accounts[0] })
        .then((receipt) => {
          console.log("Transaction successful", receipt);
        })
        .catch((error) => {
          console.error("Transaction failed:", error);
        });
    } catch (error) {
      window.alert(error);
    }
  };

  // const im =  result?.data?.tillDate;
  return (
    <>
    <div className="w-full rounded-lg shadow-lg">
          <details className="group">
            <summary className="flex justify-center rounded-lg items-center font-medium px-5 cursor-pointer list-none hover:bg-zinc-100">
              <div className=" flex py-5 px-10 w-full justify-between items-center ">
                <img src={data?.collectionImage} alt="img" className=" w-12 h-12 rounded-full" />
                <div className=" flex flex-col items-center gap-3">
                  <h2 className="text-sm font-semibold">Project Name</h2>
                  <span className="text-sm font-semibold text-center text-wrap w-32">{data?.displayName}</span>
                </div>
                <div className=" flex flex-col items-center gap-3">
                  <h2 className="text-sm font-semibold">Project Type</h2>
                  <span className="text-sm font-semibold">{data?.collectionType}</span>
                </div>
                <div className=" flex flex-col items-center gap-3">
                  <h2 className="text-sm font-semibold">Total Raise</h2>
                  <span className="text-sm font-semibold">{data?.totalRaise}</span>
                </div>
                <div className=" flex flex-col items-center gap-3">
                  <h2 className="text-sm font-semibold">Ended Date</h2>
                  <span className="text-sm font-semibold">{data?.tillDate?.substring(0, 10)}</span>
                </div>
                <div className=" flex flex-col items-center gap-3">
                  <h2 className="text-sm font-semibold">Status</h2>
                  <span className="text-sm font-semibold">{data?.status}</span>
                </div>
              </div>
            </summary>
            <div className="mt-3 group-open:animate-fadeIn">
              {/* Expanded information */}
                    <div
                  className={`flex justify-between items-end py-6  mb-4 px-10 rounded-lg`}
                >
                  <div className="flex gap-6">
                    <div>
                      <p
                        className={`mb-4 font-semibold ${
                          theme === "light" ? "text-black" : "text-white"
                        } `}
                      >
                        Reward Earned
                      </p>
                      <p className=" font-semibold ">
                        Maturity Date
                      </p>
                    </div>
                    <div>
                      <p
                        className={`mb-4 ${
                          theme === "light" ? "text-black" : "text-white"
                        }`}
                      >
                        00.00 OG
                      </p>
                      <p className={theme === "light" ? "text-black" : "text-white"}>
                        DD/MM/YYYY 00.00
                      </p>
                    </div>
                  </div>

                  <div className=" flex gap-3">
                    <Link key={data?._id} to={`/project/${data._id}`}>
                        <div className=" px-3 py-2 font-semibold rounded-md text-xl text-white bg-gradient-to-tr from-blue-400 to-green-500">
                          View Details
                        </div>
                    </Link>
                    <Button
                      icon={<WalletOutlined />}
                      className="wallet-btn btn ml-3 text-white font-medium "
                      onClick={() => {
                        openModal();
                      }}
                    >
                      Invest Now
                    </Button>
                  </div>
                </div>
            </div>
          </details>
          {isOpen && (
          <div
            className="flex justify-center h-30 w-screen   align-middle z-999   top-0 absolute"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              width: "100%",
              position: "fixed",
              left: "0",
              display: "flex",
              transform: "translateY(-50%)",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              top: "50%",
            }}
          >
            <div
              className=" bg-white p-5 border-r-8 w-full max-w-96 "
              style={{ height: "maxcontent" }}
            >
              <h2 className="font-bold">Enter Amount</h2>
              <input
                type="text"
                placeholder="Enter value*"
                value={inputValue}
                onChange={handleInputChange}
                className="outline-none p-2 border-b border-black w-full "
              />
              <div className="float-right mt-3 flex gap-3">
                <button onClick={closeModal} className=" hover:bg-red-400 hover:text-white p-1 rounded-md">Cancel</button>
                <button
                  onClick={() =>
                    handleButtonClick(info[indexValue]?.address, indexValue + 1)
                  }

                  className="hover:bg-primary-1 hover:text-white p-1 rounded-md"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        </div>
      
    </>
  );
};

export default Cards;
