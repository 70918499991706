import React from 'react'

function RealWorld() {
  return (
    <section className=' w-full p-10 bg-white '>
         <div className="flex items-center justify-center mb-8">
          <div
            className="w-1/5 h-1"
            style={{
              background:
                "linear-gradient(to right, rgba(4,162,135,1), rgba(4,162,135,0))",
            }}
          ></div>
          <h2
            className="text-4xl font-bold mx-4 p-4"
            style={{
              background:
                "linear-gradient(0deg, rgba(4,162,135,1) 0%, rgba(38,145,215,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Real World Assets
          </h2>
          <div
            className="w-1/5 h-1"
            style={{
              background:
                "linear-gradient(to left, rgba(4,162,135,1), rgba(4,162,135,0))",
            }}
          ></div>
        </div>

        <div className=' xl:flex-row flex-col flex w-full justify-between'>
            <div className=' xl:w-1/2 w-full flex justify-center'>
                <img src='/images/real.svg' alt='real-world-assets' className='w-[350px]' />
            </div>

            <div className='xl:w-1/2 w-full flex flex-col'>
                <h1 className=' text-neutral-600 font-semibold text-3xl'>Unlock the Future of Real Estate Investment</h1>

                <p className=' mt-5 text-lg text-neutral-500'>Experience unparalleled access and liquidity in real estate markets with BitViraj’s blockchain-powered platform. Tokenize high-value properties, enabling fractional ownership and secure transactions. Invest with confidence, transparency, and global reach. Join the real estate revolution today!</p>
                <p className=' mt-5 text-lg text-neutral-500'>Tokenize and invest in real-world assets with BitViraj’s blockchain technology. Enjoy fractional ownership, enhanced liquidity, and secure transactions. Access a global marketplace with unparalleled transparency and efficiency. Transform your investment strategy today!</p>
            </div>
    
        </div>
    </section>
  )
}

export default RealWorld