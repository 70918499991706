import React, { useEffect, useState } from "react";
import { MdAddToPhotos } from "react-icons/md";
import prop1 from "../../assets/images/property/prop1.jpeg";
import { FcClock } from "react-icons/fc";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiFolderAddFill } from "react-icons/ri";
import AddCollectionModal from "./components/AddCollectionModal";
import axios from "axios";
import { Link } from "react-router-dom";



function BuilderDashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchCollection = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/v0/collection/get-creator-collection`,
          {
            headers: {
              token: token,
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.responseData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    fetchCollection();
  }, [isModalOpen]);

  return (
    <div className=" flex flex-col p-10 bg-white">
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div class="loader"></div>
        </div>
      ) : (
        <>
          <h1 className=" text-3xl text-neutral-800 font-semibold text-center">
            Builder Dashboard
          </h1>
          <div className=" flex p-1 items-center bg-[#dadada] rounded-full self-end">
            <button className=" px-6 py-2 text-white text-xs font-semibold bg-[#4e4d4d] rounded-full ">
              Properties
            </button>
            <button className=" px-6 py-2 text-[#4e4d4d] font-semibold text-xs rounded-full ">
              Analytics
            </button>
          </div>

          <div className=" flex  flex-col h-screen overflow-y-auto border border-[#a7a7a7] mt-10 rounded-md">
            <div className=" sticky top-0 left-0 bg-white flex justify-between p-5 border-b border-[#a7a7a7]">
              <h1 className=" text-xl font-semibold text-neutral-800">
                {data.length} {data.length > 1 ? "Collections" : "Collection"}
              </h1>
              {/* ----- Add collection button ------------ */}
              <button
                onClick={handleOpenModal}
                className="overflow-hidden px-6 py-2 bg-[#4e4d4d] text-white border-none rounded-full text-sm font-bold cursor-pointer relative z-10 group"
              >
                Add Collection
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                <span className="absolute w-36 h-32 -top-8 -left-2 bg-primary-1-light rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                <span className="absolute w-40 h-32 -top-8 -left-2 bg-primary-1 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2 left-16 z-10">
                  <MdAddToPhotos className="text-xl" />
                </span>
              </button>

              {/* ---- add collection form --- */}
              <AddCollectionModal
                open={isModalOpen}
                handleClose={handleCloseModal}
              />
            </div>

            <div className=" flex flex-col h-full w-full gap-5 px-8 py-5">
              {data.length === 0 ? (
                <div className=" w-full h-full flex flex-col items-center justify-center">
                  <RiFolderAddFill className=" text-8xl text-primary-1" />
                  <p className="w-[500px] text-center text-wrap text-lg font-semibold text-neutral-600">
                    No Collections here, click on Add Collection button to
                    create your first collection{" "}
                  </p>
                </div>
              ) : (
                data.map((d, i) => (
                  <div className=" flex px-5 py-3 items-center justify-between border border-[#a7a7a7] rounded-md shadow-md ">
                    <img
                      src={d.collectionImage}
                      alt="collection"
                      className=" w-16 h-16 rounded-md"
                    />
                    <h1 className=" w-64 text-lg text-neutral-700 font-bold text-wrap">
                      {d.displayName}
                    </h1>
                    <div className=" flex flex-col gap-2 items-center">
                      <h1 className="text-sm text-neutral-500 font-semibold">
                        Total Properties
                      </h1>
                      <h1 className="text-lg text-neutral-700 font-bold">
                        {d.placeNftCount}
                      </h1>
                    </div>
                    <div className=" flex flex-col gap-2 items-center">
                      <h1 className="text-sm text-neutral-500 font-semibold">
                        Pending Properties
                      </h1>
                      <h1 className="text-lg text-neutral-700 font-bold">
                        {d.pendingNft}
                      </h1>
                    </div>
                    <div className=" flex flex-col gap-2 items-center">
                      <h1 className="text-sm text-neutral-500 font-semibold">
                        Admin Verified
                      </h1>
                      <h1 className="text-2xl text-primary-1 font-bold">
                        {d.isVerify ? <IoIosCheckmarkCircle /> : <FcClock />}
                      </h1>
                    </div>
                    <Link key={d?._id} to={`/buildernft/${d?._id}`}>
                      <span className="px-4 py-2 text-white font-semibold rounded-md bg-gradient-to-tr from-[#3b9183] to-[#04a287]">
                        View Details
                      </span>
                    </Link>
                    <div className="relative">
                      <button className=" text-neutral-800 peer focus:text-gray-200 transition-all duration-200  ">
                        <BsThreeDotsVertical className=" text-3xl" />
                      </button>
                      <div
                        className=' w-48 absolute top-5 right-2 z-10 shadow-lg
		after:content-[""] after:inline-block after:absolute after:top-0 after:bg-white
		after:w-full after:h-full after:-z-20  after:rounded-lg
    peer-focus:top-10 peer-focus:opacity-100 peer-focus:visible 
    transition-all duration-300 invisible  opacity-0 
    '
                      >
                        <ul className="  flex flex-col ">
                          <Link to={`/builderaddnft/${d._id}`}>
                            <li className="cursor-pointer px-3 py-3 font-semibold hover:bg-primary-1 hover:text-white rounded-md hover:opacity-90 text-neutral-800 text-sm ">
                              Add Property
                            </li>
                          </Link>
                          <li className="cursor-pointer px-3 py-3 font-semibold hover:bg-primary-1 hover:text-white rounded-md hover:opacity-90 text-neutral-800 text-sm">
                            Edit Collection Info
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BuilderDashboard;
