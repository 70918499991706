import React, { useState } from 'react'
import prop1 from "../../../assets/images/property/prop1.jpeg";
import { FaEdit } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

function EditCollection() {

    const {collectionId} = useParams();

    const nftDummyData = [
        {
            id: 1,
            name: "Flat A-101",
        },
        {
            id: 2,
            name: "Flat A-102",
        },
        {
            id: 3,
            name: "Flat A-103",
        },
    ]

    const [collectionFormData, setCollectionFormData] = useState({
        collectionImage: prop1,
        bannerImage: prop1,
        description: "Godrej Estates is a premier residential community that epitomizes luxury and modern living in the heart of the city. Designed with a blend of contemporary architecture and lush green landscapes, Godrej Estates offers a serene and sophisticated lifestyle for its residents. Each home is meticulously crafted to provide the utmost comfort and elegance, ensuring a perfect blend of functionality and aesthetics. The community is strategically located, providing easy access to essential amenities, making it an ideal choice for those seeking convenience and luxury.\n\nKey Features:Spacious and elegantly designed apartments State-of-the-art fitness center.Swimming pool with a dedicated children's play area\nMulti-purpose clubhouse for social gatherings\n24/7 security with advanced surveillance systems\nBeautifully landscaped gardens and walking trails\nAmple parking space for residents and guests\nHigh-speed elevators and modern infrastructure\nProximity to shopping centers, schools, and healthcare facilities",
        displayName: 'Godrej Estates',
        AttributeLocation: 'Pune',
        startDate: "2024-07-12T11:10",
        endDate: "2024-11-12T14:02",
    });

    const [imagePreviews, setImagePreviews] = useState({
        collectionImagePreview: prop1,
        bannerImagePreview: prop1,
    });


    const handleFileChange = (event, callback) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                callback(reader.result, file);
            };
        }
    };

    const handleCollectionChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "file") {
            handleFileChange(e, (base64, file) => {
                setCollectionFormData((prevData) => ({
                    ...prevData,
                    [name]: base64
                }));
                setImagePreviews((prevPreviews) => ({
                    ...prevPreviews,
                    [`${name}Preview`]: URL.createObjectURL(file)
                }));
            });
        } else {
            setCollectionFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
    };

  return (
    <div className=' m-20 p-10 bg-white rounded-md flex flex-col'>
        <h1 className=' text-3xl px-8 text-primary-1 font-semibold'>Edit Collection</h1>
        <form onSubmit={handleSubmit} className=' w-full'>
                <div className='grid p-8 grid-cols-4 bg-white rounded-xl  w-full gap-10'>
                 

                    {/* collection Name */}
                    <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Collection Name</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="displayName"
                        id="displayName"
                        value={collectionFormData.displayName}
                        onChange={handleCollectionChange}
                        />
                    </div>

                    {/* location */}
                    <div className='col-span-2 flex flex-col gap-1'>
                        <h1 className='text-sm font-semibold text-heading ml-3'>Attribute Location</h1>
                        <input
                        className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                        type="text"
                        name="AttributeLocation"
                        id="AttributeLocation"
                        value={collectionFormData.AttributeLocation}
                        onChange={handleCollectionChange}
                        />
                    </div>

                               

                    {/* Collection Image */}
            <div className='col-span-2 flex flex-col gap-1'>
                <h1 className='text-sm font-semibold text-heading ml-3'>Collection Image</h1>
                <div className=' flex gap-8'>
                <input
                    className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                    type="file"
                    name="collectionImage"
                    id="collectionImage"
                    onChange={handleCollectionChange}
                />
                {imagePreviews.collectionImagePreview && (
                    <img
                        src={imagePreviews.collectionImagePreview}
                        alt="Collection Preview"
                        className="mt-2 w-32 h-32 object-cover rounded-lg"
                    />
                )}
                </div>
            </div>

            {/* Banner Image */}
            <div className='col-span-2 flex flex-col gap-1'>
                <h1 className='text-sm font-semibold text-heading ml-3'>Banner Image</h1>
                <div className=' flex gap-8'>
                <input
                    className="mt-1 pl-5 py-2 border border-neutral-400 text-heading rounded-lg shadow-sm focus:outline-none focus:border-emerald-400"
                    type="file"
                    name="bannerImage"
                    id="bannerImage"
                    onChange={handleCollectionChange}
                />
                {imagePreviews.bannerImagePreview && (
                    <img
                        src={imagePreviews.bannerImagePreview}
                        alt="Banner Preview"
                        className="mt-2 w-32 h-32 object-cover rounded-lg"
                    />
                )}
                </div>
            </div>
                  

                     

                    {/* Start date */}
                    <div className="relative col-span-2">
                        <input
                        name="startDate"
                        onChange={handleCollectionChange}
                        type="datetime-local"
                        value={collectionFormData.startDate}
                        id="startDate"
                        className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        />
                        <label
                        htmlFor="startDate"
                        className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                        Start Date
                        </label>
                    </div>

                    {/* End Date */}
                    <div className="relative col-span-2">
                        <input
                        name="endDate"
                        onChange={handleCollectionChange}
                        type="datetime-local"
                        value={collectionFormData.endDate}
                        id="endDate"
                        className="block font-semibold px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-white rounded-lg outline-1 border border-black outline-neutral-400 focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        />
                        <label
                        htmlFor="endDate"
                        className="absolute text-lg text-gray-500 duration-300 transform -translate-y-4 scale-75 top-0 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                        End Date
                        </label>
                    </div>

                  

                    {/* Description */}
                    <div className='col-span-4 flex flex-col'>
                        <h1 className='text-sm font-semibold text-heading'>Description</h1>
                        <textarea
                        className="mt-1 pl-5 py-2 border border-neutral-400 focus:outline-none focus:border-emerald-400  text-heading rounded-md shadow-sm"
                        name="description"  
                        id="description"     
                        value={collectionFormData.description}         
                        onChange={handleCollectionChange}            
                        rows={5}            
                        />
                    </div>


                    <div className=' col-span-4 flex flex-col mx-10 my-5'>
                        <div className=' grid grid-cols-4 px-10 py-3 border border-neutral-300'>
                            <h1 className=' col-span-3 text-neutral-500 font-semibold'>Property Name</h1>
                            <h1 className=' col-span-1 text-neutral-500 flex justify-center font-semibold'>Edit Property</h1>
                        </div>
                        {nftDummyData.map((item,i) => (

                        <div className=' grid grid-cols-4 px-10 py-3  border border-neutral-300'>
                            <h1 className=' col-span-3 text-neutral-800 font-bold'>{item.name}</h1>
                            <Link to={`/collection/${collectionId}/editnft/${item.id}`} className=' col-span-1 flex justify-center  text-primary-1 font-bold'><FaEdit /></Link>
                        </div>

                        ))}
                        
                    </div>

                 
            <div className=' col-span-4 flex  justify-end gap-5'>
          <button className=' px-8 py-1 rounded-md font-semibold hover:bg-neutral-600 text-white bg-neutral-700'>
            Cancel
          </button>
          <button type="submit" className=' px-10 bg-primary-1 hover:scale-95 transition-all text-white py-2 rounded-md font-semibold'>Submit</button> 
            </div>
          </div>

          </form>
    </div>
  )
}

export default EditCollection